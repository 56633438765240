import apiClient from "@/apiClient";

class ProductService {

  async index() {
    try {
      return await apiClient.get("/api/product/");
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export default ProductService;

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import CheckBoxField from '@/components/CheckBoxField'
import CurrencyField from '@/components/CurrencyField'
import DateField from '@/components/DateField'
import TextField from '@/components/TextField'
import { v4 as uuidv4 } from 'uuid'

const emits = defineEmits(['delete', 'save'])

const props = defineProps({
  cols: { type: Array, required: true },
  data: { type: Array, required: true },
  addable: { type: Boolean, required: false, default: false },
  editable: { type: Boolean, required: false, default: false },
  deletable: { type: Boolean, required: false, default: false },
})

const dataCopy = ref(props.data)
const computedData = computed(() => {
  return dataCopy.value
})

/*
    Manage row selection for use when editing a specific row
*/
const selectedRow = ref({})
const isSelected = row => {
  return Boolean(
    (row.id === selectedRow.value.id && row?.id > 0) ||
    (row.uuid === selectedRow.value.uuid && row?.uuid),
  )
}
const isEditable = (col, row) => col.editable && isSelected(row)

/*
    Component to use based on type
*/
const getCompnentType = (type, editable) => {
  switch (type) {
    case 'checkbox':
      return CheckBoxField

    case 'currency':
      return CurrencyField

    case 'date':
      return DateField

    default:
      return TextField
  }
}

/*
 Add a new row
*/
const addRow = () => {
  let newData = {}
  props.cols.forEach(col => {
    if (col.editable) {
      switch (col.type) {
        case 'checkbox':
          newData[col.row] = 1
          break
        case 'currency':
          newData[col.row] = 0.0
          break
        case 'date':
          newData[col.row] = new Date().toISOString().split('T')[0]
          break
        default:
          newData[col.row] = ''
      }
    }
  })
  newData.uuid = uuidv4()
  dataCopy.value.push(newData)
  selectedRow.value = newData
}

/*
 Handle deleting, editing and saving rows
*/
const deleteRow = row => {
  emits('delete', row)
  dataCopy.value = dataCopy.value.filter(item => item.id !== row.id)
}
const editRow = row => (selectedRow.value = row)
const saveRow = row => {
  emits('save', row)
  selectedRow.value = {}
}
const update = (row, colKey, data) => (row[colKey] = data)
</script>
<template>
  <div>
    <table class="md:table-auto w-full text-left mb-2">
      <thead>
        <tr>
          <th v-for="col in props.cols" :key="col.name">{{ col.name }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="row in computedData" :key="row">
          <td v-for="col in props.cols" :key="col">
            <component :is="getCompnentType(col.type, isEditable(col, row))" :editable="isEditable(col, row)"
              :data="row[col.row]" @update="data => update(row, col.row, data)" />
          </td>

          <td v-if="props.editable" class="text-right whitespace-nowrap">
            <edit-button v-if="!isSelected(row)" @click.prevent="editRow(row)" :disableTitle="true" />
            &nbsp;
            <save-button v-else @click.prevent="saveRow(row)" :disableTitle="true" />
            &nbsp;
            <delete-button v-if="props.deletable" @delete="deleteRow(row)" :disableTitle="true" />
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button v-if="props.addable" class="btn add" @click.prevent="addRow">
        + Add
      </button>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import { ArrowLeftOnRectangleIcon } from "@heroicons/vue/24/solid";

let auth = useAuthStore();
</script>
<template>
  <ArrowLeftOnRectangleIcon
    class="h-6 w-6 text-white"
    @click.prevent="auth.logout()"
    >Logout</ArrowLeftOnRectangleIcon
  >
</template>

<script setup>
import { useRoute } from 'vue-router'
import apiClient from '@/apiClient.js'
import { onMounted, ref } from 'vue'
import CustomerBlank from '@/views/Customer/CustomerBlank.vue'

const route = useRoute()
let customerId = route.params.id
let customer = ref('hello')

onMounted(() => {
  apiClient.get('/api/customer/' + customerId).then(r => {
    customer.value = r.data
  })
})

function save() {
  console.log(customer.value)
}
</script>
<template>
  <div class="box flex">
    <div class="flex-1"></div>
    <div>
      <button class="btn save" @click.prevent="save()">Save</button>
    </div>
  </div>
  <CustomerBlank v-model:customer="customer" editable="true" />
</template>

<template>
  <input
    type="number"
    :value="displayValue"
    @blur="formatValue"
    @input="updateValue"
  />
</template>

<script setup>
import { ref, watch } from 'vue'

const emit = defineEmits(['update:price'])
const props = defineProps({
  price: {
    type: Number,
    default: 0,
  },
})

const inputPrice = ref(props.price / 100)
const displayValue = ref(inputPrice.value.toFixed(2))

// This function runs whenever the input loses focus
const formatValue = () => {
  const value = parseFloat(inputPrice.value)
  if (!isNaN(value)) {
    // Format the display value to two decimal places
    displayValue.value = value.toFixed(2)
    // Emit the value in cents to the parent
    emit('update:price', Math.round(value * 100))
  } else {
    // If the parsed value is NaN, reset the input value
    displayValue.value = (props.price / 100).toFixed(2)
  }
}

// This function runs whenever the input value changes
const updateValue = event => {
  // Remove any non-numeric characters except for the decimal point
  inputPrice.value = event.target.value.replace(/[^\d.]/g, '')
}

// Watch the price prop for changes
watch(
  () => props.price,
  newPrice => {
    // Update the display value if the prop changes
    inputPrice.value = newPrice / 100
    displayValue.value = inputPrice.value.toFixed(2)
  },
)
</script>

import { createRouter, createWebHistory } from "vue-router";
import AccommodationView from "../views/HomeView.vue";
import BankTransactionsView from "../views/HomeView.vue";
import CalendarView from "@/views/CalendarView.vue";
import CalendarDayView from "@/views/CalendarDayView.vue";
import CalendarCreate from "@/views/CalendarCreate.vue";
import ContactView from "@/views/Contact/ContactView.vue";
import CustomerCreate from "@/views/Customer/CustomerCreate.vue";
import CustomerEdit from "@/views/Customer/CustomerEdit.vue";
import CustomerList from "@/views/Customer/CustomerList.vue";
import CustomerView from "@/views/Customer/CustomerView.vue";
import HomeView from "../views/HomeView.vue";
import EventRisk from "../views/Events/EventRisk.vue";
import InvoiceCreate from "@/views/Invoice/InvoiceCreate.vue";
import InvoiceList from "@/views/Invoice/InvoiceList.vue";
import InvoiceView from "@/views/Invoice/InvoiceView.vue";
import JobList from "@/views/Job/JobList.vue";
import ReferenceCreate from "@/views/Reference/ReferenceCreate.vue";
import ReferenceCustomer from "@/views/Reference/ReferenceCustomer.vue";
import ReferenceImport from "@/views/Reference/ReferenceImport.vue";
import ReferenceView from "@/views/Reference/ReferenceView.vue";
import StaffCreate from "@/views/Staff/StaffCreate.vue";
import StaffExport from "@/views/Staff/StaffExport.vue";
import StaffList from "@/views/Staff/StaffList.vue";
import StaffView from "@/views/Staff/StaffView.vue";
import TicketsCreate from "@/views/Ticket/TicketsCreate.vue";
import TicketsList from "@/views/Ticket/TicketsList.vue";
import TicketsView from "@/views/Ticket/TicketsView.vue";
import TheLoginView from "../views/TheLoginView.vue";
import VehicleIndex from "@/views/Vehicles/VehicleIndex.vue";
import VehicleCreate from "@/views/Vehicles/VehicleCreate.vue";

const routes = [
  // Accommodation
  {
    path: "/accommodation",
    component: AccommodationView,
    meta: {
      breadcrumbs: [{ name: "Accommodation", link: "/accommodation" }],
    },
  },

  // Home (Calendar)
  {
    path: "/",
    name: "calendar",
    component: CalendarView,
    meta: {
      breadcrumbs: [{ name: "Calendar", link: "/calendar" }],
      title: "Calendar",
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: CalendarView,
    meta: {
      breadcrumbs: [{ name: "Calendar", link: "/calendar" }],
      title: "Calendar",
    },
  },

  // Bank Statements
  {
    path: "/bank-transactions",
    component: BankTransactionsView,
    meta: {
      breadcrumbs: [{ name: "Bank Transactions", link: "/bank-transactions" }],
    },
  },

  // Login
  {
    path: "/login",
    name: "login",
    component: TheLoginView,
    meta: {
      title: "Login",
    },
  },

  // Calendar
  {
    path: "/calendar",
    component: CalendarView,
    name: "calendar",
    meta: {
      breadcrumbs: [{ name: "Calendar", link: "/calendar" }, { value: true }],
      title: "Calendar",
    },
  },
  {
    path: "/calendar/create",
    component: CalendarCreate,
    name: "calendar-create",
    meta: {
      breadcrumbs: [
        { name: "Calendar", link: "/calendar" },
        { name: "Create" },
      ],
      title: "Calendar Entry",
    },
  },
  {
    path: "/calendar/day",
    component: CalendarDayView,
    name: "calendar-day",
    meta: {
      breadcrumbs: [{ name: "Calendar", link: "/calendar" }, { value: true }],
      title: "Calendar",
    },
  },

  // Contact
  {
    path: "/contact/:id",
    name: "contact-view",
    component: ContactView,
    meta: {
      breadcrumbs: [{ name: "Contacts", link: "" }, { value: true }],
      title: "Contact",
    },
  },

  // Customer
  {
    path: "/customer",
    name: "customer-list",
    component: CustomerList,
    meta: {
      breadcrumbs: [{ name: "Customers", link: "/customer" }],
      title: "Customers",
    },
  },
  {
    path: "/customer/:id",
    name: "customer-view",
    component: CustomerView,
    meta: {
      breadcrumbs: [{ name: "Customers", link: "/customer?page=1" }, { value: true }],
      title: "Customers",
    },
  },
  {
    path: "/customer/:id/edit",
    name: "customer-edit",
    component: CustomerEdit,
    meta: {
      title: "Edit Customer",
      parent: null,
    },
  },
  {
    path: "/customer/create",
    name: "customer-create",
    component: CustomerCreate,
    meta: {
      breadcrumbs: [
        { name: "Customers", link: "/customer" },
        { name: "Create" },
      ],
      title: "Create Customer",
    },
  },
  {
    path: "/customer/:id",
    name: "customer-view",
    component: CustomerView,
    meta: {
      breadcrumbs: [{ name: "Customers", link: "/customer" }, { value: true }],
    },
  },

  {
    path: "/invoice",
    name: "invoice-list",
    component: InvoiceList,
    meta: {
      breadcrumbs: [{ name: "Invoices", link: "/invoice" }],
      title: "Invoices",
    },
  },
  {
    path: "/invoice/:id",
    name: "invoice-view",
    component: InvoiceView,
    meta: {
      breadcrumbs: [{ name: "Invoices", link: "/invoice" }, { value: true }],
      title: "View Invoice",
    },
  },
  {
    path: "/invoice/create",
    name: "invoice-create",
    component: InvoiceCreate,
    meta: {
      breadcrumbs: [{ name: "Invoices", link: "/invoice" }, { name: "Create" }],
      title: "Create Invoice",
    },
  },

  {
    path: "/job",
    name: "Jobs",
    component: JobList,
    meta: {
      breadcrumbs: [{ name: "Jobs", link: "/job" }],
    },
  },

  // Staff
  {
    path: "/staff",
    name: "staff-list",
    component: StaffList,
    meta: {
      breadcrumbs: [{ name: "Staff", link: "/staff" }],
      title: "Staff",
    },
  },
  {
    path: "/staff/export",
    name: "staff-export",
    component: StaffExport,
    meta: {
      breadcrumbs: [{ name: "Staff", link: "/staff" }, { name: "Export" }],
      title: "Staff Export",
    },
  },
  {
    path: "/staff/create",
    name: "staff-create",
    component: StaffCreate,
    meta: {
      breadcrumbs: [
        { name: "Staff Create", link: "/staff/create" },
        { value: true },
      ],
      title: "Add Staff",
    },
  },
  {
    path: "/staff/:id",
    name: "staff-view",
    component: StaffView,
    meta: {
      breadcrumbs: [{ name: "Staff", link: "/staff" }, { value: true }],
      title: "Staff Information",
    },
  },

  // Tickets
  {
    path: "/tickets",
    name: "tickets",
    component: TicketsList,
    meta: {
      breadcrumbs: [{ name: "Tickets", link: "/tickets" }],
      title: "Tickets",
    },
  },
  {
    path: "/tickets/create",
    name: "tickets-create",
    component: TicketsCreate,
    meta: {
      breadcrumbs: [{ name: "Tickets", link: "/tickets" }],
      title: "Create ticket",
    },
  },
  {
    path: "/tickets/:id",
    name: "tickets-view",
    component: TicketsView,
    meta: {
      breadcrumbs: [{ name: "Tickets", link: "/tickets" }, { value: true }],
      title: "View Ticket",
    },
  },

  // Reference
  {
    path: "/reference/create",
    name: "reference-create",
    component: ReferenceCreate,
  },
  {
    path: "/reference/import",
    name: "reference-import",
    component: ReferenceImport,
    meta: {
      breadcrumbs: [{ name: "Job", link: "/job" }, { name: "Import" }],
      title: "Job",
    },
  },
  {
    path: "/reference/:id",
    name: "reference-view",
    component: ReferenceView,
    meta: {
      breadcrumbs: [{ name: "Job", link: "/job" }, { value: true }],
      title: "Job",
    },
  },
  {
    path: "/reference/:id/event-risk",
    name: "event-risk",
    component: EventRisk,
    meta: {
      breadcrumbs: [{ name: "Job", link: "/job" }, { value: true }, { name: "Event Risks" }],
      title: "Job",
    },
  },
  {
    path: "/reference/customer",
    name: "reference-customer",
    component: ReferenceCustomer,
  },


  // Vehicles
  {
    path: "/vehicles",
    name: "vehicles",
    component: VehicleIndex,
    meta: {
      breadcrumbs: [{ name: "Vehicles", link: "/vehicles" }],
      title: "Vehicles",
    },
  },
  {
    path: "/vehicles/create",
    name: "vehicles-create",
    component: VehicleCreate,
    meta: {
      breadcrumbs: [
        { name: "Vehicles", link: "/vehicles" },
        { name: "Create" },
      ],
      title: "Vehicles Create",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<script setup>
import apiClient from '@/apiClient.js'
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const referenceTypes = ref({})
const searchQuery = ref('')
const searchResults = ref({})
const showCreateForm = ref(true)
const reference = ref({ type: null, name: '' })

onMounted(async () => {
  const response = await apiClient.get('/api/reference/types')
  referenceTypes.value = response.data
  reference.value.type = referenceTypes.value[0].id ?? null
})

const createFormBtn = () => {
  showCreateForm.value = true
}

const changeInput = () => {
  console.log(searchQuery.value)
}

const selectReference = val => {
  console.log(val)
}
</script>
<template>
  <form-box header="Reference Search">
    <searchable-input
      :url="'/api/reference/search?query='"
      :output="val => val.txt + ' - ' + val.name"
      @selected="selectReference"
      v-model="searchResults"
      placeholder="Find or create reference"
    />

    <div
      v-if="searchQuery?.length && showCreateForm === false"
      class="overflow-auto border border-t-0 w-full h-72 bg-white dark:dark:bg-slate-700 p-2"
    >
      <button
        class="hover:bg-sky-100 dark:hover:dark:bg-slate-800 p-2 rounded block w-full text-left"
        @click.prevent="createFormBtn"
      >
        + Add {{ searchQuery }}
      </button>
      <div
        v-for="r in searchResults"
        :key="r"
        class="hover:bg-gray-100 dark:hover:dark:bg-slate-800 p-2 rounded cursor-pointer"
        @click="() => selected(r)"
      >
        {{ r }}
      </div>
    </div>
    <div v-if="showCreateForm === true" class="mt-6">
      <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div>
          <label for="referenceType">Type</label>
          <select v-model="reference.type" id="referenceType">
            <option
              v-for="type in referenceTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <div>
          <label for="referenceName">Name / Description</label>
          <TextField
            :data="reference.name"
            :editable="true"
            @update="val => (reference.name = val)"
          />
        </div>
      </form>
    </div>
  </form-box>
</template>

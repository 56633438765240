<script setup>
import { useCalendarStore } from '@/stores/CalendarStore'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { defineProps, onMounted } from 'vue'
import { useBreadcrumbStore } from '@/stores/BreadcrumbStore'

const props = defineProps({
  view: { type: String, required: true },
})

let calendarStore = useCalendarStore()
const route = useRoute()
const breadcrumbStore = useBreadcrumbStore()

onMounted(async () => {
  calendarStore.updateDateFromRoute(route)
  update()
})

const update = () => {
  window.history.replaceState(
    null,
    null,
    '/calendar/?date=' + dayjs(calendarStore.getDate).format('YYYY-MM-DD'),
  )
  breadcrumbStore.setValue(dayjs(calendarStore.getDate).format('MMMM YYYY'))
}

const changeMonth = amount => {
  calendarStore.changeMonth(amount)
  update()
}

const changeDay = amount => {
  calendarStore.changeDay(amount)
  update()
}
</script>
<template>
  <div class="flex w-full">
    <div class="flex-1 mb-6">
      <div class="flex gap-5">
        <router-link
          :to="{ name: 'reference-create' }"
          class="accent rounded text-xl px-2"
        >
          +
        </router-link>
        <h1 class="text-2xl" v-if="view === 'month'">
          {{ dayjs(calendarStore.getDate).format('MMMM YYYY') }}
        </h1>
        <h1 class="text-2xl" v-else>
          {{ dayjs(calendarStore.getDate).format('ddd,  Do MMMM YYYY') }}
        </h1>
      </div>
    </div>

    <div v-show="props.view == 'month'">
      <button
        class="accent rounded py-2 px-4 mr-2"
        @click.prevent="changeMonth(-1)"
      >
        Prev
      </button>
      <button class="accent rounded py-2 px-4" @click.prevent="changeMonth(1)">
        Next
      </button>
    </div>

    <div v-show="view == 'day'">
      <button
        class="accent rounded py-2 px-4 mr-2"
        @click.prevent="changeDay(-1)"
      >
        Yesterday
      </button>
      <button
        class="accent rounded py-2 px-4 mr-2"
        @click.prevent="changeDay(1)"
      >
        Tomorrow
      </button>
    </div>
  </div>
</template>

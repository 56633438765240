<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  header: { type: String },
  box: { type: Boolean, default: true },
  collapsible: { type: Boolean, default: false },
  collapsed: { type: Boolean, default: false },
});

const expanded = ref(!props.collapsed);

watch(
  () => props.collapsed,
  (newVal) => {
    expanded.value = !newVal;
  },
);

const toggleExpand = () => (expanded.value = !expanded.value);
</script>
<template>
  <div :class="{ box: props.box }" class="w-full h-full">
    <div class="flex justify-between header-container">
      <header v-if="props.header">
        {{ props.header }}
      </header>
      <div v-else></div>
      <div class="whitespace-nobreak">
        <div class="inline-block flex gap-2">
          <slot name="nav"></slot>
        </div>
        <button class="cursor-pointer inline-block" @click.prevent="toggleExpand" v-if="props.collapsible">
          <template v-if="expanded">
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 5 5 1 1 5" />
            </svg>
          </template>
          <template v-else>
            <svg data-accordion-icon="" class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 5 5 1 1 5"></path>
            </svg>
          </template>
        </button>
      </div>
    </div>
    <hr class="pb-2" />
    <slot v-if="expanded"></slot>
  </div>
</template>

import axios from "axios";
import { apiUrlRoot } from "@/vars";
import { useAuthStore } from "@/stores/AuthStore";

axios.defaults.withXSRFToken = true;
const apiClient = axios.create({
  baseURL: apiUrlRoot,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
});

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authenticated");
      return;
    }

    if (error.response && error.response.status === 422) {
      alert(error?.response?.data?.message);
      return;
    }

    // Network Error
    if (error.code === "ERR_NETWORK") {
      location.reload();
    }

    if (error.code === "ERR_BAD_REQUEST") {
      console.log(error);
    }

    // Other error
    else if (
      error.config &&
      error.config.url &&
      !error.config.url.includes("/api/ping")
    ) {
      //alert(error.code)
      location.reload();
    } else {
      //location.reload()
    }
    return Promise.reject(error);
  },
);

// Add a request interceptor
apiClient.interceptors.request.use(
  function (config) {
    // If the method is POST, log "complete"
    if (config.method === "post") {
      //this.$emit('request-complete', 'complete')
    }

    // Return the config so the request can continue
    return config;
  },
  function (error) {
    // Do something with request error if needed
    return Promise.reject(error);
  },
);

export default apiClient;

/*
import axios from 'axios'
import { apiUrlRoot } from '@/vars'
import { useAuthStore } from '@/stores/AuthStore'

axios.defaults.withXSRFToken = true
const apiClient = axios.create({
  baseURL: apiUrlRoot,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
})

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authenticated')
      return
    }

    if (error.response && error.response.status === 422) {
      alert(error?.response?.data?.message)
      return
    }

    // Network Error
    if (error.code === 'ERR_NETWORK') {
      location.reload()
    }

    if (error.code === 'ERR_BAD_REQUEST') {
      console.log(error)
    }

    // Other error
    else if (
      error.config &&
      error.config.url &&
      !error.config.url.includes('/api/ping')
    ) {
      alert(error.code)
      location.reload()
    } else {
      //location.reload()
    }
    return Promise.reject(error)
  },
)

// Add a request interceptor
apiClient.interceptors.request.use(
  function (config) {
    // If the method is POST, log "complete"
    if (config.method === 'post') {
      //this.$emit('request-complete', 'complete')
    }

    // Return the config so the request can continue
    return config
  },
  function (error) {
    // Do something with request error if needed
    return Promise.reject(error)
  },
)

export default apiClient
*/

<script setup>
import { onMounted, ref, } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { FlagIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import Reference from "@/components/ReferenceNumber.vue"
import dayjs from "dayjs";
import CurrencyField from "@/components/CurrencyField.vue";

var relativeTime = require("dayjs/plugin/relativeTime");
var localisedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(localisedFormat)

const list = useListStoreNew();
const counters = ref({});
const route = useRoute();
const router = useRouter();
const filter = ref("");

const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

onMounted(async () => {
  list.configure({ remote: "/api/invoice", local: "/invoice", });
  filter.value = route.query?.af ?? "unpaid" // default filter to apply to list
  applyFilter()
  list.fetchIndex()
});

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.page === undefined) {
    load();
  }
  next();
});

const applyFilter = () => {
  list.clearFilters();
  if (filter.value === "unpaid") {
    list.addFilter('paid_at', '0')
    list.addFilter('sent_at', '1')
  } else if (filter.value === "unsent") {
    list.addFilter('sent_at', '0')
  } else if (filter.value === "paid") {
    list.addFilter('paid_at', '1')
  } else if (filter.value === "scheduled") {
    list.addFilter('scheduled', '1')
  }
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const load = () => {
  applyFilter();
  setUrl();
  list.resetPage();
};


const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }
  router.push({
    name: "invoice-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.subject);
}; </script>
<template>
  <div>
    <div class="box">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/invoice/search?query="
          :output="(val) => { return val.reference.txt + ' - ' + val.reference.name }" @selected="view" />
        <router-link to="/invoice/create" class="btn accent" title="Create New">
          <PlusCircleIcon class="icon" />
        </router-link>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-5 mt-4 gap-2">
        <button @click.prevent="() => {
          setFilter('all');
        }
          " class="btn-plain bg-sky-500">
          All
        </button>
        <button class="btn-plain bg-indigo-500" @click.prevent="() => {
          setFilter('scheduled');
        }
          ">
          Scheduled
        </button>
        <button class="btn-plain bg-teal-500" @click.prevent="() => {
          setFilter('unsent');
        }
          ">
          Unsent
        </button>
        <button class="btn-plain bg-rose-500" @click.prevent="() => {
          setFilter('unpaid');
        }
          ">
          Unpaid
        </button>
        <button class="btn-plain bg-pink-500" @click.prevent="() => {
          setFilter('paid');
        }
          ">
          Paid
        </button>
      </div>
    </div>
    <table class="box list" v-if="!list.isLoading">
      <thead class="hidden xl:table-header-group">
        <tr>
          <th class="p-3 text-center w-10 lg:w-32 min-w-10 w-32  hidden sm:table-cell">
          </th>
          <th class="p-3 max-w-32 truncate hidden lg:table-cell">
          </th>
          <th class="p-3 truncate max-w-36 lg:max-w-96">
          </th>
          <th class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            Subtotal
          </th>
          <th class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            VAT
          </th>
          <th class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            Total
          </th>
          <th class="p-3 truncate max-w-36 lg:max-w-12 hidden lg:table-cell">
          </th>
          <th class="p-3 truncate max-w-36 lg:max-w-12  hidden xl:table-cell">
            Updated
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list.getIndex" :key="row.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          @click.prevent="view(row)">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10 w-32 hidden sm:table-cell">
            <Reference :reference="row?.reference" v-if="row?.reference?.type" class="w-24" />
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell hidden lg:table-cell">
            {{ row?.reference?.customer?.company }}
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-64">
            {{ row?.reference?.name }}
          </td>
          <td class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            <CurrencyField :data="row.subtotal" />
          </td>
          <td class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            <CurrencyField :data="row.vat" />
          </td>
          <td class="p-3 truncate w-20 text-right hidden 2xl:table-cell">
            <CurrencyField :data="row.subtotal + row.vat" />
          </td>
          <td class="p-3 overflow-clip max-w-36 lg:max-w-12 hidden lg:table-cell">
            <label class="label-clickable bg-pink-500 w-20" :title="dayjs(row?.reference?.paid_at).format('LL')"
              v-if="row.paid_at">Paid</label>
            <label class="label-clickable bg-rose-500 w-20" :title="dayjs(row?.reference?.paid_at).format('LL')"
              v-else-if="row.sent_at">Unpaid</label>
            <label class="label-clickable bg-indigo-500 w-20" :title="dayjs(row?.reference?.paid_at).format('LL')"
              v-else-if="row.scheduled_at">Scheduled</label>
            <label class="label-clickable bg-teal-500 w-20" :title="dayjs(row?.reference?.paid_at).format('LL')"
              v-else>Unsent</label>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-12 hidden xl:table-cell">
            <span :title="dayjs(row?.reference?.updated_at).format('LL')">{{ dayjs(row?.reference?.updated_at).fromNow()
              }}</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="8" class="p-4" v-if="list.getIndex.length < 1">
            No data to display
          </td>
        </tr>
        <tr>
          <th colspan="8" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>
    <table class="box list" v-else>
      <tbody>
        <!-- Repeat this skeleton row as needed -->
        <tr v-for="n in 24" :key="n" class="border-b border-gray-300 animate-pulse">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 text-right hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="text-right w-4">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

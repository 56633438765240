<script setup>
import { useListStore } from '@/stores/ListStore'

const emit = defineEmits(['selected', 'update:modelValue'])
const props = defineProps({
  output: Function,
  add: { type: String, required: false },
  select: { type: Function, required: false },
  url: { type: String, required: true },
})

function repeatSelected(val) {
  console.log(val)
  emit('selected', val)
}

const list = useListStore()
</script>
<template>
  <div>
    <searchable-input
      :url="props.url"
      :output="props.output"
      placeholder="Search..."
      @selected="repeatSelected"
    />
    <div
      v-show="list.searchQuery.length"
      class="overscroll-auto overflow-auto border border-t-0 rounded w-full h-48 p-2 dark:bg-slate-800"
    >
      <router-link
        v-if="list.searchQuery.length && props.addUrl"
        :to="{
          name: props.addUrl,
          params: { name: list.searchQuery },
        }"
        class="hover:bg-gray-100 dark:hover:bg-gray-900 p-2 rounded block"
        >+ Add {{ list.searchQuery }}</router-link
      >
      <div
        v-for="r in list.searchResults"
        :key="r"
        class="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer p-2 my-1 rounded select-none"
      >
        <router-link
          :to="{
            path: list.urls.local + '/' + r.id,
          }"
          class="hover:bg-gray-100 dark:hover:bg-gray-900 rounded block"
          >{{ props.output(r) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

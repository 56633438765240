<script setup>
import apiClient from '@/apiClient.js'
import { computed, onMounted, ref } from 'vue'
import { FlagIcon } from '@heroicons/vue/24/solid'

const emit = defineEmits(['update:status'])

const props = defineProps({
  title: { default: 'Flag' },
  altTitle: { default: 'Unflag' },
  status: { required: true },
  url: { required: true },
})

const flagged = ref(false)

const toggle = () => {
  emit('update:status', !props.status) // immediately change the flag for responsive feeling UI
  apiClient.put(props.url).then(response => {
    emit('update:status', response.data.flagged_at)
    flagged.value = !flagged.value
  })
}

onMounted(() => {
  flagged.value = Boolean(props.status)
})

const statusComputed = computed(() => Boolean(props.status))
</script>
<template>
  <button class="btn" @click.prevent="toggle">
    <FlagIcon class="icon" />
    <template v-if="!flagged">
      {{ props.title }}
    </template>
    <template v-else>
      {{ props.altTitle }}
    </template>
  </button>
</template>

import apiClient from "@/apiClient";

class ListService {
  filters = [];
  sort = "";
  column = "";
  direction = "";
  page;

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.page = 1;
  }

  async index() {
    try {
      let filter = Object.entries(this.filters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      let url = `${this.baseUrl}?page=${this.page}&sort=${this.column}&direction=${this.direction}${this.getFilters() ? "&" + filter : ""}`;

      const response = await apiClient.get(url);

      if (response.status != 200) {
        throw new Error("Network response error");
      }
      return await response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async show() {
    try {
      const response = await apiClient.get(`${this.baseUrl}`);
      if (!response.ok) {
        throw new Error("Network response error");
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  addFilter(key, value) {
    this.filters = { ...this.filters, [key]: value };
  }

  clearFilters() {
    this.filters = [];
  }

  changePage(change) {
    this.page += change;
  }

  getPage() {
    return this.page;
  }

  getFilters() {
    return Object.entries(this.filters)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }

  setPage(page) {
    this.page = parseInt(page);
  }
}

// Export the class itself, not an instance
export default ListService;

import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import calendarService from '@/services/CalendarService'

export let useCalendarStore = defineStore('calendar', {
  state: () => ({
    date: new Date(),
    entries: [],
    error: null,
  }),

  actions: {
    updateDateFromRoute(route) {
      const dateFromURL = route.query.date
        ? new Date(route.query.date)
        : new Date()
      this.setDate(dayjs(dateFromURL))
    },

    changeMonth(amount) {
      const newDate = dayjs(this.date).add(amount, 'month')
      this.setDate(newDate)
    },

    changeDay(amount) {
      const newDate = dayjs(this.date).add(amount, 'day')
      this.setDate(newDate)
    },

    async fetchCalendarData() {
      try {
        const response = await calendarService.index(
          this.getYear,
          this.getMonth,
        )
        this.entries = response
      } catch (error) {
        this.error = error
      }
    },

    setDate(date) {
      this.date = date
      this.fetchCalendarData()
    },
  },

  getters: {
    getEntries: state => state.entries ?? [],

    getEntriesForDay: state => day => {
      const formattedDate = dayjs(day ?? state.date).format('YYYY-MM-DD')
      return state.entries.filter(entry => entry.date === formattedDate)
    },

    getDateRange: state => {
      const range = []
      const first = dayjs(state.date).startOf('month').day()
      let date = dayjs(state.date)
        .startOf('month')
        .subtract(first - 1, 'day')

      while (range.length < 42) {
        range.push(date)
        date = date.add(1, 'day')
      }
      return range
    },

    getDate: state => state.date,
    getYear: state => dayjs(state.date).format('YYYY'),
    getMonth: state => dayjs(state.date).format('MM'),
    getError: state => state.error,
  },
})

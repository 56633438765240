<script setup>
import { watch, computed, defineEmits, defineProps, ref } from 'vue'

const emits = defineEmits(['update'])

const props = defineProps({
  data: { type: [null, String], required: true },
  editable: { type: Boolean, required: false, default: false },
})

const displayValue = ref('')

// Function to format date for input type="date"
const formatDateForInput = dateString => {
  if (!dateString) return ''
  const date = new Date(dateString)
  if (isNaN(date)) return ''

  return date.toISOString().split('T')[0] // Format as "YYYY-MM-DD"
}

const update = () => {
  emits('update', displayValue.value)
}

// Watch for changes in props.data and update displayValue
watch(
  () => props.data,
  newValue => {
    displayValue.value = formatDateForInput(newValue)
  },
  { immediate: true },
)

const computedDisplayValue = computed(() => {
  // Assuming you still want to display the date in a different format
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(props.data))
})
</script>

<template>
  <input
    v-if="editable"
    type="date"
    v-model="displayValue"
    @input="update"
    :disabled="!props.editable"
  />
  <span v-else>
    <template v-if="props.data">{{ computedDisplayValue }}</template></span
  >
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import CalendarHeader from "@/components/CalendarHeader.vue";

import { useCalendarStore } from "@/stores/CalendarStore";

const calendarStore = useCalendarStore();
const route = useRoute();

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

onMounted(async () => {
  try {
    calendarStore.updateDateFromRoute(route);
    await calendarStore.getEntriesForDay(null);
  } catch (error) {
    console.log(error);
  }
});
</script>
<template>
  <div class="h-full box">
    <CalendarHeader
      class="col-span-7 w-full min-h-0"
      view="day"
    ></CalendarHeader>
    <div
      class="grid grid-cols-1 gap-2"
      v-if="calendarStore.getEntriesForDay(null).length"
    >
      <div
        v-for="entry in calendarStore.getEntriesForDay(null)"
        :key="entry.id"
        class="cursor-pointer m-auto w-full max-w-4xl border rounded p-4 mb-4"
      >
        <div class="mb-4">
          <span class="rounded bg-green-400 dark:bg-green-600 p-2 mr-4">{{
            entry.reference.txt
          }}</span>
          <span class="">{{ entry.reference.name }}</span>
        </div>
        <div>{{ entry.reference.description }}</div>
      </div>
    </div>
    <div v-else>There are no calendar entries to display.</div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const location = ref("");
const locations = ref([]);
const center = ref({ lat: 53.2487471, lng: -4.3048407 });
const zoom = ref(6);

const addLocation = () => {
  locations.value.push({
    location: location.value,
    position: {
      lat: 53.2487471, lng: -4.3048407
    },
  });
  location.value = ""
}
</script>
<template>
  <form-box header="Location">
    <label for="location">Location</label>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <input type="text" v-model="location" placeholder="Enter postcode or address" class="w-full" />
      <button class="btn w-20" @click.prevent="addLocation">Add</button>
      <div class="bg-sky-950 p-2">
        <GMapMap ref="map" :center="center" :zoom="zoom" map-type-id="terrain" class="w-full h-96">
          <GMapMarker :key="index" v-for="(m, index) in locations" :position="m.position" />
        </GMapMap>
      </div>
      <div>
        <div v-for="loc in locations" :key="loc" class="p-2">
          {{ loc.location }}
        </div>
      </div>
    </div>
  </form-box>
</template>

<script setup>
import { onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import Router from "@/router/index.js";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import { useListStore } from "@/stores/ListStore";
import { useListStoreNew } from "@/stores/ListStoreNew";
import { useCalendarStore } from "@/stores/CalendarStore";

const breadcrumbStore = useBreadcrumbStore();
const list = useListStore();
const listNew = useListStoreNew();
const calendarStore = useCalendarStore();

var breadcrumbs = ref("");
const route = useRoute();
watch(
  () => route.meta.breadcrumbs,
  (crumbs) => {
    breadcrumbs.value = crumbs;
  },
  { immediate: true },
);
const view = (link) => {
  console.log("visintg " + link);
  Router.push(link);

  // Temporary ugliness until ListStore is rewritten
  if (link == "/calendar") {
    calendarStore.updateDateFromRoute(route);
    calendarStore.fetchCalendarData();
  }

  // Please recactor!
  if (link == "/tickets") {
    listNew.fetchIndex();
  }
};

onMounted(() => { });
</script>
<template>
  <div class="flex flex-wrap w-full">
    <span v-for="crumb in breadcrumbs" :key="crumb.link || crumb.name" class="cursor-pointer">
      <span v-if="crumb.link !== undefined" @click.prevent="view(crumb.link)"
        class="p-3 text-accent whitespace-normal hover:cursor">
        {{ crumb.name }} >
      </span>
      <span v-else-if="crumb.name !== undefined" class="p-3 text-accent whitespace-normal hover:cursor">{{ crumb.name
        }}</span>
      <span v-else-if="crumb.value !== undefined && crumb.value === true" class="p-3 text-accent whitespace-normal">
        {{ breadcrumbStore.getValue }}
      </span>
    </span>
  </div>
</template>

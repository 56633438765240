import apiClient from '@/apiClient.js'

const index = async (year, month) => {
  const url = '/api/calendar/' + year + '/' + month
  const response = await apiClient.get(url)
  return response.data
}

const getDay = async (year, month, day) => {
  const url = '/api/calendar/' + year + '/' + month + '/' + day
  const response = await apiClient.get(url)
  return response.data
}

const get = async id => {
  const url = '/api/calendar/' + id
  const response = await apiClient.get(url)
  return response.data
}

const create = async (reference_type_id, date, description) => {
  const data = {
    name: description,
    date,
    time: '00:00',
    reference_type_id,
  }
  const response = await apiClient.post('/api/calendar', data)
  return response.data
}

export default {
  index,
  get,
  getDay,
  create,
}

<script setup>
import apiClient from '@/apiClient.js'
import { defineEmits, defineProps, ref } from 'vue'
const emits = defineEmits(['selected'])
const createNew = ref(false)
const contact = ref({})

const searchResultOutput = contact => contact.name + ' <' + contact.email + '>'
const selected = val => emits('selected', val)

const noResults = val => {
  createNew.value = true
}

const someResults = val => {
  createNew.value = false
}

const save = () => {
  apiClient
    .post('/api/contact', contact.value)
    .then(r => {
      contact.value = r.data
      emits('selected', contact.value)
      createNew.value = false
    })
    .catch(e => {})
}
</script>
<template>
  <div>
    <div>
      <searchable-input
        class="my-4"
        placeholder="Enter customer name"
        url="/api/contact/search?query="
        :output="searchResultOutput"
        @selected="selected"
        @empty="noResults"
        @found="someResults"
        :canAdd="true"
      />
    </div>
    <form class="grid grid-cols-auto md:grid-cols-2 gap-4" v-if="createNew">
      <label>Name</label>
      <input type="text" v-model="contact.name" />
      <label>Email</label>
      <input type="email" v-model="contact.email" />
      <label>Phone Number</label>
      <input type="text" v-model="contact.phone" />
    </form>
    <button v-if="createNew" class="btn" @click.prevent="save">Save</button>
    <button v-if="createNew" @click.prevent="createNew = false" class="btn">
      Cancel
    </button>
  </div>
</template>

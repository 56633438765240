<script setup>
import apiClient from '@/apiClient.js'
import { onMounted, ref, defineProps } from 'vue'
import dayjs from 'dayjs'

const courses = ref([])
const activeCourses = ref([])
const selectedCourse = ref(0)
const selectedDate = ref(dayjs().format('YYYY-MM-DD'))

const props = defineProps({
  staff: { type: Object, required: true },
})

onMounted(() => {
  // Existing courses staff has attended
  props.staff.courses.forEach(course =>
    addCourse(course.id, course.name, course.pivot.attended),
  )

  // Get available courses
  try {
    apiClient.get('/api/staff/courses').then(r => {
      courses.value = r.data
      selectedCourse.value = courses.value[0].id
    })
  } catch (error) {
    console.log(error)
  }
})

// Add course staff has attended to local list
const addCourse = (id, name, attended) => {
  activeCourses.value.push({
    id,
    name,
    attended,
  })
}

// Add course button is clicked, add to course list
const add = () => {
  if (!selectedCourse.value || !selectedDate.value) {
    return alert('Course and completion date must be complete')
  }

  const index = courses.value.findIndex(
    course => course.id === selectedCourse.value,
  )

  addCourse(
    courses.value[index].id,
    courses.value[index].name,
    selectedDate.value,
  )

  selectedDate.value = dayjs().format('YYYY-MM-DD')
  selectedCourse.value = courses.value[0].id
  save()
}

const edit = item => {
  item.editing = true
}

const del = item => {
  try {
    apiClient
      .patch('/api/staff/' + props.staff.id + '/course', {
        course_id: item.id,
      })
      .then(r => {
        const index = activeCourses.value.findIndex(
          course => course.id === item.id,
        )
        if (index > -1) {
          activeCourses.value.splice(index, 1)
        }
      })
  } catch (error) {
    console.log(error)
  }
}

const save = () => {
  console.log(activeCourses.value)
  try {
    apiClient
      .patch('/api/staff/' + props.staff.id + '/courses', {
        courses: activeCourses.value,
      })
      .then(r => {})
  } catch (error) {
    console.log(error)
  }

  activeCourses.value.forEach(course => {
    course.editing = false
  })
}
</script>
<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
    <span>Course Name</span>
    <span>Attended Date</span>
    <span></span>
    <select class="w-full" v-model="selectedCourse">
      <option v-for="course in courses" :key="course" :value="course.id">
        {{ course.name }}
      </option>
    </select>
    <input type="date" v-model="selectedDate" class="w-full" />
    <div class="text-right">
      <button class="btn w-full md:w-auto" @click.prevent="add">Add</button>
    </div>

    <template v-for="course in activeCourses" :key="course">
      <span>{{ course.name }}</span>
      <input
        type="date"
        v-model="course.attended"
        v-if="course.editing"
        @change="save"
      />
      <span class="" v-else>{{
        new Date(course.attended).toLocaleDateString('en-GB')
      }}</span>
      <div class="whitespace-nowrap md:text-right">
        <EditButton :disableTitle="true" @edit="edit(course)" />
        <DeleteButton :disableTitle="true" @delete="del(course)" />
      </div>
    </template>
  </div>
</template>

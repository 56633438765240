<script setup>
import { defineProps, computed, ref } from "vue";

const props = defineProps({
  reference: { type: Object, required: true },
});

const labelClass = computed(() => {
  switch (props.reference?.type?.prefix) {
    case "M1E":
      return "label-event";
    case "M1P":
      return "label-transport";
    case "M1S":
      return "label-transport";
    case "M1T":
      return "label-training";
    case "PCR":
      return "label-job";
  }

  return "";
});
</script>
<template>
  <label class="label-bubble" :class="labelClass">
    <router-link :to="'/reference/' + props.reference.id">{{
      props.reference.txt
      }}</router-link>
  </label>
</template>

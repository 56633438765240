import { defineStore } from 'pinia'
//import Router from "@/router/index.js";
//import apiClient from "@/apiClient";

export let useBreadcrumbStore = defineStore('breadcrumb', {
  state() {
    return {
      value: '',
    }
  },

  actions: {
    setValue(val) {
      this.value = val
    },
  },

  getters: {
    getValue() {
      return this.value
    },
  },
})

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({ route: String });
const router = useRouter();
</script>
<template>
  <div class="border rounded p-4 bg-gray-100">
    <button @click.prevent="router.push({ name: props.route })">
      <slot></slot>
    </button>
  </div>
</template>

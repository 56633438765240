import apiClient from "@/apiClient";

class TicketService {

  async index() {
    try {
      return await apiClient.get("/api/ticket");
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async counters() {
    try {
      let response = await apiClient.get("/api/ticket/counters");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export default TicketService;

<script setup>
import { onMounted, ref, computed } from 'vue'
import apiClient from '@/apiClient.js'
import * as Icon from '@heroicons/vue/24/solid'

const courses = ref([])
const activeCourses = ref({})
const results = ref([]) // To store the filtered results
const headers = ref([]) // To store the headers
const showResults = ref(false) // To control the visibility of the results box

onMounted(() => {
  try {
    apiClient.get('/api/staff/courses').then(r => {
      courses.value = r.data
    })
  } catch (error) {
    console.log(error)
  }
})

const filterResults = () => {
  const selectedCourseIds = Object.keys(activeCourses.value)
    .filter(key => activeCourses.value[key])
    .map(Number)

  const filters = {
    courses: selectedCourseIds,
  }

  try {
    apiClient
      .post('/api/staff/export', filters, {
        responseType: 'json', // Ensure the response type is set to 'json'
      })
      .then(response => {
        // Check if the response data is valid and not empty
        if (!response.data || response.data.length === 0) {
          alert('No data available for the selected filters.')
          return
        }

        const lines = response.data.split('\n').filter(line => line)
        headers.value = lines[0].split(',') // Store the headers
        results.value = lines.slice(1) // Store the results
        showResults.value = true // Show the results box

        console.log('Headers:', headers.value) // Debugging line to check headers
        console.log('Results:', results.value) // Debugging line to check results
      })
      .catch(error => {
        console.error('Error fetching results:', error)
        alert('An error occurred while fetching the results. Please try again.')
      })
  } catch (error) {
    console.error('Error:', error)
    alert('An unexpected error occurred. Please try again.')
  }
}

const exportCsv = () => {
  const selectedCourseIds = Object.keys(activeCourses.value)
    .filter(key => activeCourses.value[key])
    .map(Number)

  const filters = {
    courses: selectedCourseIds,
  }

  try {
    apiClient
      .post('/api/staff/export', filters, {
        responseType: 'blob', // Ensure the response type is set to 'blob'
      })
      .then(response => {
        // Check if the response data is valid and not empty
        if (!response.data || response.data.size === 0) {
          alert('No data available for the selected filters.')
          return
        }

        const blob = new Blob([response.data], { type: 'text/csv' })

        // Generate a timestamp for the filename
        const timestamp = new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/:/g, '-')

        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `staff_export_${timestamp}.csv` // Set the desired file name with timestamp

        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch(error => {
        console.error('Error exporting CSV:', error)
        alert('An error occurred while exporting the CSV. Please try again.')
      })
  } catch (error) {
    console.error('Error:', error)
    alert('An unexpected error occurred. Please try again.')
  }
}
</script>
<template>
  <form-box header="Filter Staff">
    <template v-slot:nav>
      <button class="btn save" @click.prevent="filterResults" title="Filter">
        <Icon.CloudArrowDownIcon class="icon" /> Filter
      </button>
    </template>
  </form-box>

  <form-box header="Mandatory Training">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      <div
        v-for="course in courses"
        :key="course.id"
        class="flex items-center gap-2"
      >
        <input
          type="checkbox"
          :id="'course-' + course.id"
          v-model="activeCourses[course.id]"
          class="form-checkbox"
        />
        <label :for="'course-' + course.id" class="cursor-pointer">{{
          course.name
        }}</label>
      </div>
    </div>
  </form-box>

  <form-box v-if="showResults" header="Results">
    <template v-slot:nav>
      <button class="btn save" @click.prevent="exportCsv" title="Export">
        <Icon.CloudArrowDownIcon class="icon" /> Export
      </button>
    </template>
    <div class="overflow-x-auto max-w-screen-lg">
      <table class="table-auto w-full border-collapse">
        <thead>
          <tr class="uppercase text-sm leading-normal">
            <th
              v-for="header in headers"
              :key="header"
              class="px-4 py-2 border"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in results" :key="index" class="border-b">
            <td
              v-for="field in result.split(',')"
              :key="field"
              class="px-4 py-2 border"
            >
              {{ field }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form-box>
</template>

import { defineStore, onMounted, onBeforeUnmount } from "pinia";
import { ref } from "vue";
import Router from "@/router/index.js";
import apiClient from "@/apiClient.js";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";

export let useListStore = defineStore("list", {
  state() {
    return {
      raw: String,

      urls: {
        index: "",
        search: "",
        remote: "",
        local: "",
      },
      list: [],
      columns: [],
      filters: {},
      data: ref({}),
      automaticRefresh: false,
      loading: false,

      attributes: {
        highlightRow: (val) => {
          return 0;
        },
      },

      searchQuery: "",
      searchResults: {},

      pages: {
        prev: "",
        next: "",
        active: 1,
      },

      sort: {
        column: "id",
        direction: 0,
      },

      resultsCount: 0,
    };
  },

  actions: {
    configure(config) {
      this.columns = config.columns || this.columns;
      this.attributes = config.attributes || this.attributes;
      this.urls.remote = config.remote || "";
      this.urls.local = config.local || "";
      const params = new URLSearchParams(window.location.search);
      const page = params.get("page") || 1;
      this.pages.active = Number(page);
      this.setUrl(this.urls.remote + "?page=" + this.pages.active);
      this.setupEventListener();
      this.automaticRefresh = config.refresh || false;

      if (this.automaticRefresh) {
        //setInterval(() => this.load(), 30000);
      }
    },

    setupEventListener() {
      window.addEventListener("popstate", () => {
        const params = new URLSearchParams(document.location.search);
        const page = params.get("page") ?? 1;
        this.pages.active = Number(page);
        this.urls.index = this.urls.remote + "?page=" + page;
        this.load();
      });
    },

    setRemote(url) {
      this.urls.remote = url || "";
    },

    view(id, name = "") {
      const localUrl = this.urls.local || "/";
      const url = `${localUrl}/${id}`;
      Router.push(url);
      const breadcrumbStore = useBreadcrumbStore();
      breadcrumbStore.setValue(name);
    },

    addFilter(key, value) {
      this.filters = { ...this.filters, [key]: value };
    },

    clearFilters() {
      this.pages.active = 1;
      this.filters = {};
      this.setUrl(this.urls.remote + "?page=" + this.pages.active, false);
    },

    load() {
      let direction = this.sort.direction === 0 ? "ASC" : "DESC";
      let column = this.sort.column || "id";
      let filter = Object.entries(this.filters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      let url = `${this.urls.index}&sort=${column}&direction=${direction}${filter ? "&" + filter : ""}`;
      console.log("loading", url);

      apiClient
        .get(url)
        .then((r) => {
          this.data = r.data;
          this.resultsCount = this.data?.data?.length;
          this.setPages();
        })
        .catch((e) => {});
    },

    setPages() {
      if (this.data.prev_page_url) {
        let parsePrev = new URL(this.data.prev_page_url);
        this.pages.prev = parsePrev.pathname + parsePrev.search;
      } else {
        this.pages.prev = "";
      }

      if (this.data.next_page_url) {
        let parseNext = new URL(this.data.next_page_url);
        this.pages.next = parseNext.pathname + parseNext.search;
      } else {
        this.pages.next = "";
      }
    },

    prevPage() {
      if (this.pages.prev.length) {
        this.pages.active--;
        this.setUrl(this.pages.prev);
      }
    },

    nextPage() {
      if (this.pages.next.length) {
        this.pages.active++;
        this.setUrl(this.pages.next);
      }
    },

    setUrl(url, reload = true) {
      const localUrl = new URL(window.location.href);
      localUrl.searchParams.set("page", this.pages.active);
      window.history.pushState({}, "", localUrl);
      this.urls.index = url || "";

      if (reload) {
        this.load();
      }
    },

    search() {
      if (this.searchQuery.length < 1) return;
      apiClient
        .get((this.urls.remote || "") + "/search?query=" + this.searchQuery)
        .then((r) => {
          this.searchResults = r.data;
        })
        .catch((e) => {});
    },

    searchClear() {
      this.searchQuery = "";
      this.searchResults = {};
    },

    sort(column) {
      this.sort.column = column;
      this.sort.direction = this.sort.direction === 0 ? 1 : 0;
      this.load();
    },

    saveState() {
      localStorage.setItem("listState", JSON.stringify(this.$state));
    },

    loadState() {
      const state = localStorage.getItem("listState");
      if (state) {
        this.$patch(JSON.parse(state));
      }
    },
  },

  getters: {
    showPrev() {
      return Boolean(this.pages.prev);
    },

    showNext() {
      return Boolean(this.pages.next);
    },

    numberOfResults() {
      return this.resultsCount;
    },
  },
});

// In your component
export default {
  setup() {
    const listStore = useListStore();

    onMounted(() => {
      listStore.loadState();
      listStore.load();
    });

    onBeforeUnmount(() => {
      listStore.saveState();
    });

    return {
      listStore,
    };
  },
};

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import confetti from 'canvas-confetti'

const confettiContainer = ref(null)

const triggerConfetti = () => {
  confetti({
    particleCount: 200,
    spread: 160,
    origin: { y: 0.6 },
  })
}

onMounted(() => {
  window.triggerConfetti = triggerConfetti
})

onBeforeUnmount(() => {
  window.triggerConfetti = null
})
</script>
<template>
  <div
    ref="confettiContainer"
    class="fixed top-0 left-0 w-full h-full pointer-events-none"
  ></div>
</template>
<style scoped></style>

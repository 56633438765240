import VehicleIndexVue from './VehicleIndex.vue';
<script setup>
import { ref } from "vue";
import apiClient from "@/apiClient";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const registrationNumber = ref("");
const saveBtnActive = ref(true);

const addVehicle = () => {
  if (registrationNumber.value.length < 3) {
    return alert("Registration number must be longer");
  }
  saveBtnActive.value = false;
  apiClient
    .post("/api/vehicle", { registrationNumber: registrationNumber.value })
    .then((r) => {
      router.push({
        name: "vehicles",
      });
    });
};
</script>
<template>
  <form-box header="Add Vehicle">
    <label for="registration">Registration Number</label>
    <div class="flex">
      <input
        type="text"
        class="uppercase"
        v-model="registrationNumber"
        :disabled="!saveBtnActive"
      />
      <div class="px-2">
        <button class="btn save" @click.prevent="addVehicle">Save</button>
      </div>
    </div>
  </form-box>
</template>

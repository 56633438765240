<template>
  <span>{{ formattedPrice }}</span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  price: {
    type: Number,
    required: true,
  },
})

// Computed property that formats the price as currency
const formattedPrice = computed(() => {
  return (props.price / 100).toFixed(2)
})
</script>

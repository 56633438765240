<script setup>
import { defineEmits, defineProps, ref } from 'vue'

const emits = defineEmits(['update'])

const props = defineProps({
  data: { type: [String, null], required: true },
  editable: { type: Boolean, required: false, default: false },
})

const displayValue = ref(props.data)

const update = () => emits('update', displayValue.value)
</script>
<template>
  <input
    v-if="props.editable"
    type="text"
    v-model="displayValue"
    @input="update"
    class="w-full"
  />
  <span v-else>{{ displayValue }}</span>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import LoginView from "@/views/TheLoginView.vue";
import LayoutDefault from "@/views/Layout/LayoutDefault.vue";
import apiClient from "@/apiClient.js";
import router from "@/router/index.js";

const auth = useAuthStore();

/* Set the page title */
router.beforeEach((to, from, next) => {
  let title = auth.isLoggedIn ? to.meta.title : "Login";
  document.title = (title ?? "Dashboard") + " - Neptune Beta";
  next();
});

/* Get CSRF Cookie */
refreshToken();
function refreshToken() {
  apiClient.get("/sanctum/csrf-cookie").then((response) => {
    const csrfToken = response.data.csrfToken;
  });
}

/* Keep hitting the server to keep connection alive or redirect to login if session expired */
setInterval(async () => {
  refreshToken();
  if (auth.isLoggedIn) {
    await apiClient
      .post("/api/ping")
      .then(() => {
        auth.renewTimeoutCookie();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          auth.logout();
        }
        if (err.response?.status === 401 || err.response?.status === 419) {
          console.log(err.response.data);
          auth.logout();
        }
      });
  }
}, 1000 * 60);
</script>

<template>
  <div v-if="auth.isLoggedIn">
    <ConfettiAnimation />
    <LayoutDefault />
  </div>
  <LoginView v-else />
</template>

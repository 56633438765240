<script setup>
import { onMounted, ref } from "vue";
import apiClient from '@/apiClient.js'

const upload = ref();
const showResults = ref(false)

const onFileChange = event => {
  console.log("uploading")
  const userFile = event.target.files[0]
  if (!userFile) {
    return
  }

  const formData = new FormData()
  formData.append('file', userFile)

  try {
    apiClient.post('/api/reference/import', formData, { headers: { 'Content-Type': 'multipart/form-data', } })
      .then(() => {
      })
  } catch (error) {
    console.error('Error uploading file', error)
  }
}
</script>
<template>
  <div>
    <form-box header="Import Jobs">
      <div class="max-w-xl py-4">
        Select a file containing a list of jobs you want to import. The type and format of your file will automatically
        be
        detected.
        If automatic detection is succesful, a list of jobs will be shown below allowing you to select which jobs should
        be imported.
      </div>
      <div>
        <label>Upload File</label>
        <input type="file" ref="upload" @change="onFileChange">
      </div>
    </form-box>
    <form-box header="Results" v-show="showResults">

    </form-box>
  </div>
</template>

<script setup>
import apiClient from '@/apiClient.js'
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const emits = defineEmits(['selected'])
const createNew = ref(false)
const customer = ref({})

const route = useRoute()

const props = defineProps({
  customerId: { type: Number, required: false },
})

const searchResultOutput = customer =>
  customer.company + '<' + customer.contact?.name + '>'
const selected = val => emits('selected', val)

const noResults = val => {
  createNew.value = true
}

const someResults = val => {
  createNew.value = false
}

const save = () => {
  apiClient
    .post('/api/customer', customer.value)
    .then(r => {
      customer.value = r.data
      emits('selected', customer.value)
      createNew.value = false
    })
    .catch(e => {})
}

onMounted(() => {
  const id = route.query.customerId ?? null
  if (id) {
    apiClient
      .get('/api/customer/' + id)
      .then(r => {
        emits('selected', r.data)
      })
      .catch(e => {
        alert(e)
      })
  }
})
</script>
<template>
  <div>
    <div>
      <searchable-input
        class="my-4"
        placeholder="Type customer name"
        url="/api/customer/search?query="
        :output="searchResultOutput"
        @selected="selected"
        @empty="noResults"
        @found="someResults"
        :canAdd="true"
      />
    </div>
    <form
      class="grid grid-cols-auto md:grid-cols-2 gap-4 mb-4"
      v-if="createNew"
    >
      <div>
        <label>Add Company</label>
        <input type="text" v-model="customer.company" />
      </div>
    </form>
    <button v-if="createNew" class="btn" @click.prevent="save">Save</button>
    <button v-if="createNew" @click.prevent="createNew = false" class="btn">
      Cancel
    </button>
  </div>
</template>

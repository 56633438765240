<script setup>
import { watch, computed, defineEmits, defineProps, ref } from 'vue'

const emits = defineEmits(['update'])

const props = defineProps({
  data: { type: [null, String], required: true },
  editable: { type: Boolean, required: false, default: false },
})

const displayValue = ref('')

// Function to format time for input type="time"
const formatTimeForInput = timeString => {
  if (!timeString) return ''
  const [hours, minutes] = timeString.split(':')
  if (isNaN(hours) || isNaN(minutes)) return ''
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
}

const update = () => {
  emits('update', displayValue.value)
}

// Watch for changes in props.data and update displayValue
watch(
  () => props.data,
  newValue => {
    displayValue.value = formatTimeForInput(newValue)
  },
  { immediate: true },
)

const computedDisplayValue = computed(() => {
  // Format the time in the desired format
  const [hours, minutes] = props.data.split(':')
  if (isNaN(hours) || isNaN(minutes)) return ''
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
})
</script>

<template>
  <input
    v-if="editable"
    type="time"
    v-model="displayValue"
    @input="update"
    :disabled="!props.editable"
  />
  <span v-else>
    <template v-if="props.data">{{ computedDisplayValue }}</template>
  </span>
</template>

<script setup>
import { useListStoreNew } from "@/stores/ListStoreNew";
import { defineProps, onActivated, onMounted, ref, } from "vue";
import dayjs from "dayjs";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { PlusCircleIcon } from "@heroicons/vue/24/solid";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import CurrencyField from "@/components/CurrencyField.vue";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const list = useListStoreNew();
const filter = ref("");
const router = useRouter();
const route = useRoute();
const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

onMounted(() => {
  list.configure({ remote: "/api/customer", local: "/customer", });
  filter.value = route.query?.af ?? "active" // default filter to apply to list
  applyFilter()
  list.fetchIndex()
});

onBeforeRouteUpdate(() => {
  console.log('List component activated');
});

const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }

  router.push({
    name: "customer-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.subject)
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const applyFilter = () => {
  list.clearFilters();
  if (filter.value == "active") {
    list.addFilter("active", "true");
  } else if (filter.value == "inactive") {
    list.addFilter("active", "false");
  }
}

const load = () => {
  //applyFilter()
  setUrl();
  list.resetPage();
};

</script>
<template>
  <div>
    <div class="box">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/customer/search?query=" :output="(val) => {
          return val?.company + ' - ' + val?.contact?.name + ' [' + val?.contact?.email + ']';
        }
          " @selected="view" />
        <router-link to="/customer/create" class="btn accent" title="Create New">
          <PlusCircleIcon class="icon" />
        </router-link>
      </div>
    </div>
    <table class="box list" v-if="!list.isLoading">
      <thead class="hidden xl:table-header-group">
        <tr>
          <th class="px-4 pt-2 w-36 truncate">Group</th>
          <th class="px-4 pt-2 text-left truncate">Customer</th>
          <th class="px-4 pt-2 w-1/3 hidden xl:table-cell truncate text-left">Primary Contact</th>
          <th class="px-4 pt-2 truncate w-36 text-right hidden lg:table-cell">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in list.getIndex" :key="data"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          @click.prevent="view(data)">
          <td class="p-4 w-36 hidden md:table-cell">
            <label class="label-clickable bg-amber-700 w-full" v-if="data.group?.name === 'Bronze'">
              {{ data.group.name }}
            </label>
            <label class="label-clickable bg-gray-400 w-full" v-else-if="data.group?.name === 'Silver'">
              {{ data.group?.name }}
            </label>
            <label class="label-clickable bg-yellow-400 w-full" v-else-if="data.group?.name === 'Gold'">
              {{ data.group?.name }}
            </label>
            <label class="label-clickable bg-blue-500 w-full" v-else-if="data.group?.name === 'Platinum'">
              {{ data.group?.name }}
            </label>
          </td>
          <td class=" p-4 overflow-hidden xl:table-cell">
            {{ data.company }}
          </td>
          <td class="p-4 w-1/3 overflow-hidden hidden xl:table-cell">
            {{ data.contact?.name }}
          </td>
          <td class="p-4 w-36 hidden lg:table-cell text-right">
            <CurrencyField :data="data.balance" v-if="data.balance > 0" />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="p-4" v-if="list.getIndex.length < 1">
            No data to display
          </td>
        </tr>
        <tr>
          <th colspan="3" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>

    <table class="box list" v-else>
      <tbody>
        <!-- Repeat this skeleton row as needed -->
        <tr v-for="n in 24" :key="n" class="border-b border-gray-300 animate-pulse">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 text-right hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="text-right w-4">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

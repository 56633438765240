import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import { ref } from "vue";

export function useBreadcrumbs() {
  const breadcrumbStore = useBreadcrumbStore();
  const breadcrumbValue = ref("");

  // Function to set breadcrumb value
  const setBreadcrumb = (value) => {
    breadcrumbValue.value = value;
    breadcrumbStore.setValue(value);
  };

  // Function to clear breadcrumb value
  const clearBreadcrumb = () => {
    breadcrumbValue.value = "";
    breadcrumbStore.clearValue();
  };

  return {
    breadcrumbValue,
    setBreadcrumb,
    clearBreadcrumb,
  };
}

<script setup>
import apiClient from '@/apiClient.js'
import { defineEmits, ref } from 'vue'

const emits = defineEmits(['created'])
const addAddress = ref(false)
const address = ref('')

const saveAddress = () => {
  apiClient
    .post('/api/address', {
      data: address.value,
    })
    .then(r => {
      emits('created', r.data)
      addAddress.value = false
      address.value = ''
    })
    .catch(e => {})
}
</script>
<template>
  <div class="my-2">
    <textarea
      v-if="addAddress"
      v-model="address"
      class="block w-full"
    ></textarea>
    <button
      v-if="!addAddress"
      @click.prevent="addAddress = !addAddress"
      class="btn my-2"
    >
      + Address
    </button>
    <template v-else>
      <button @click.prevent="saveAddress" class="btn">Save</button>
      <button @click.prevent="addAddress = !addAddress" class="btn">
        Cancel
      </button>
    </template>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  title: { default: 'Edit' },
  disableTitle: { type: Boolean, required: false, default: false },
})

const emit = defineEmits(['edit'])
</script>
<template>
  <button class="btn" @click.prevent="emit('edit')">
    <PencilSquareIcon class="icon" />
    <template v-if="!disableTitle">{{ props.title }}</template>
  </button>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/apiClient.js'

const route = useRoute()
const router = useRouter()

let customer = ref({
  company: route.query.customerName ?? null,
})

let contact = ref({
  name: '',
  email: '',
  phone: '',
  id: null,
})

/* Fill in contact fields */
function selectedContact(val) {
  if (val.newValue) {
    contact.value.email = val.newValue
    contact.value.name = ''
    contact.value.phone = ''
    contact.value.id = null
  } else {
    contact.value.email = val.email
    contact.value.name = val.name
    contact.value.phone = val.phone
    contact.value.id = val.id
  }
}

/* Create new customer */
async function createButton() {
  // Validation
  if (contact.value.name.length < 1 || contact.value.email.length < 1) {
    return alert('Contact must have name and email address')
  }

  // Create contact
  if (!contact.value.id) {
    const responseContact = await apiClient.post('/api/contact', contact.value)
    contact.value = responseContact.data
  }

  // Create customer
  const responseCustomer = await apiClient.post(
    '/api/customer/',
    customer.value,
  )
  customer.value = responseCustomer.data

  // Assign contact to customer
  await apiClient.post(
    '/api/customer/' + customer.value.id + '/attach-contact',
    {
      contact_id: contact.value.id,
    },
  )

  // Redirect to customer list
  router.push({
    name: route.query.returnPath ?? 'customer-list',
    query: { customerId: customer.value.id },
  })
}
</script>
<template>
  <div>
    <form-box header="Primary Contact">
      <form class="grid grid-cols-auto md:grid-cols-2 gap-4">
        <label>Email Address</label>
        <searchable-input
          :url="'/api/contact/search?query='"
          :output="val => val.email"
          @selected="selectedContact"
          v-model="contact.email"
        />

        <template v-if="contact.email">
          <label>Name</label>
          <input
            type="text"
            v-model="contact.name"
            :class="{ 'border-red-500': contact.name.length < 1 }"
            :disabled="contact.id"
          />
          <label>Phone Number</label>
          <input type="text" v-model="contact.phone" :disabled="contact.id" />
        </template>
      </form>
    </form-box>
    <form-box header="Company Information">
      <form class="grid grid-cols-auto md:grid-cols-2 gap-4">
        <label>Company</label>
        <input type="text" v-model="customer.company" />
        <label>Website</label>
        <input
          type="text"
          v-model="customer.website"
          :disabled="!contact.email"
        />
      </form>
    </form-box>

    <button @click.prevent="createButton" class="btn">Create Customer</button>
  </div>
</template>

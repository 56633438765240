<script setup>
import { onMounted, ref } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import dayjs from "dayjs";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { FlagIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/solid";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const list = useListStoreNew();
const route = useRoute();
const router = useRouter();

list.$reset();
list.configure({
  remote: "/api/vehicle",
  local: "/vehicles",
});

onMounted(() => {
  load();
});

const load = () => {
  setUrl();
  list.resetPage();
};

const setUrl = () => {
  let page = list.getPage;
  router.push({
    path: route.path,
    query: { ...route.query, page },
  });
};

const changePage = (val) => {
  list.page(val);
  setUrl();
};
</script>
<template>
  <div>
    <div class="my-4 text-right">
      <router-link to="/vehicles/create" class="btn accent" title="Create New">
        <PlusCircleIcon class="icon" /> Vehicle
      </router-link>
    </div>
    <table class="box list hidden lg:table" v-if="list.getIndex.length">
      <thead>
        <tr>
          <th class="text-left p-3">Registration Number</th>
          <th class="text-left">Mot Status</th>
          <th class="text-left">Mot Expiry</th>
          <th class="text-left">Tax Status</th>
          <th class="text-left">Tax Expiry</th>
          <th class="text-left">Year</th>
          <th class="text-left">Euro</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in list.getIndex"
          :key="row.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
        >
          <td class="p-3">{{ row.registrationNumber }}</td>
          <td>{{ row.motStatus }}</td>
          <td>
            {{ dayjs(row.motExpiryDate).fromNow() }} /
            {{ dayjs(row.motExpiryDate).format("DD MMM YY") }}
          </td>
          <td>{{ row.taxStatus }}</td>
          <td>
            {{ dayjs(row.taxDueDate).fromNow() }} /
            {{ dayjs(row.taxDueDate).format("DD MMM YY") }}
          </td>
          <td>{{ row.yearOfManufacture }}</td>
          <td>{{ row.euroStatus }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="p-4">
            <PrevNextButtons
              :showPrev="list.showPrev"
              :showNext="list.showNext"
              @prev="changePage(-1)"
              @next="changePage(1)"
            />
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="grid md:grid-cols-2 gap-4">
      <div
        class="box list block lg:hidden"
        v-for="row in list.getIndex"
        :key="row.id"
      >
        <div>
          <b>{{ row.registrationNumber }}</b>
          <div>
            MOT {{ dayjs(row.motExpiryDate).fromNow() }} /
            {{ dayjs(row.motExpiryDate).format("DD MMM YY") }}
          </div>
          <div>
            TAX {{ dayjs(row.taxDueDate).fromNow() }} /
            {{ dayjs(row.taxDueDate).format("DD MMM YY") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'

const emits = defineEmits(['update'])

const props = defineProps({
  data: { type: Number, required: true },
  editable: { type: Boolean, required: false, default: false },
})

const isChecked = ref(props.data > 0)

watch(isChecked, newValue => {
  emits('update', newValue ? 1 : 0)
})
</script>

<template>
  <input type="checkbox" v-model="isChecked" :disabled="!editable" />
</template>

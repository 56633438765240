<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/apiClient.js'
import { useAuthStore } from '@/stores/AuthStore'
import SearchContact from '@/components/SearchContact'
import { PaperClipIcon } from '@heroicons/vue/24/solid'

const router = useRouter()
const auth = useAuthStore()

const selectedContact = ref({})
const subject = ref(null)
const subjectInput = ref(null)
const responseText = ref(null)
const responseInput = ref(null)
const attachments = ref([])
const sendAnonymously = ref(0)

onMounted(() => {
  subjectInput.value.focus()
})

const addSelectedContact = contact => {
  selectedContact.value = contact
}

// User selects attachment file
const onFileChange = event => {
  attachments.value = [...event.target.files]
}

// User sends reply
function reply() {
  if (selectedContact.value.id == null) {
    return alert('Contact must be selected')
  }

  if (subject.value == null) {
    return alert('Subject is too short')
  }

  if (responseText.value === null || responseText.value.trim() === '') {
    return alert('Your message is too short')
  }

  const formData = new FormData()
  formData.append('subject', subject.value)
  formData.append('text', responseText.value)
  formData.append('contact_id', selectedContact.value.id)

  if (attachments.value.length > 0) {
    attachments.value.forEach((attachment, index) => {
      formData.append(`attachments[${index}]`, attachment)
    })
  }

  apiClient
    .post('/api/ticket/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(r => {
      router.push('/tickets/' + r.data.id)
    })
}
</script>
<template>
  <div>
    <div class="box">
      <SearchContact class="mt-4" @selected="addSelectedContact" />
      <div v-if="selectedContact.id" class="m-1">
        {{ selectedContact.name }} &lt;{{ selectedContact.email }}&gt;
      </div>
    </div>

    <div class="box">
      <input
        type="text"
        v-model="subject"
        class="block w-full mb-4"
        placeholder="Email subject.."
        ref="subjectInput"
      />
      <textarea
        class="w-full h-72 border p-2"
        v-model="responseText"
        ref="responseInput"
        wrap="soft"
      ></textarea>

      <input
        type="file"
        ref="fileInput"
        class="hidden"
        @change="onFileChange"
        multiple
      />
      <div class="flex flex-col md:flex-row justify-between w-full">
        <div
          class="flex flex-col sm:flex-row gap-4 w-full sm:w-auto mb-4 md:mb-0"
        >
          <select v-model="sendAnonymously" class="flex-1">
            <option value="0">{{ auth.name }}</option>
            <option value="1">Medic 1 team</option>
          </select>
          <button class="accent" @click.prevent="reply">Send</button>
        </div>
        <div class="flex gap-4 items-center">
          <small v-if="attachments.length" class="flex-1">
            <div v-for="attachment in attachments" :key="attachment">
              {{ attachment.name }}
            </div>
          </small>
          <span class="flex-grow"></span>
          <button
            class="accent btn pr-0 justify-right"
            @click.prevent="$refs.fileInput.click()"
          >
            <PaperClipIcon class="icon"></PaperClipIcon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

let show = ref(false)
setTimeout(() => {
  show.value = true
}, 3000)
</script>
<template>
  <div class="m-auto" v-show="show">
    <img alt="Vue logo" src="../assets/loading.svg" class="m-auto h-36" />
  </div>
</template>

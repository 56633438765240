<script setup>
import { onMounted, ref, } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { PlusCircleIcon, CloudArrowUpIcon } from "@heroicons/vue/24/solid";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import Reference from "@/components/ReferenceNumber.vue"
import dayjs from "dayjs";
import CurrencyField from "@/components/CurrencyField.vue";

var relativeTime = require("dayjs/plugin/relativeTime");
var localisedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(localisedFormat)

const list = useListStoreNew();
const counters = ref({});
const route = useRoute();
const router = useRouter();
const filter = ref("");

const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

onMounted(async () => {
  list.configure({ remote: "/api/reference", local: "/job", });
  filter.value = route.query?.af ?? "all" // default filter to apply to list
  applyFilter()
  list.fetchIndex()
});

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.page === undefined) {
    load();
  }
  next();
});

const applyFilter = () => {
  list.clearFilters();
  if (filter.value != "all") {
    list.addFilter('prefix', filter.value)
  }
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const load = () => {
  applyFilter();
  setUrl();
  list.resetPage();
};


const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }
  router.push({
    name: "reference-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.name);
}; </script>
<template>
  <div>
    <div class="box">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/reference/search?query="
          :output="(val) => { return val.txt + ' - ' + val.name }" @selected="view" />
        <div class="flex gap-2">
          <router-link to="/reference/create" class="btn accent" title="Create New">
            <PlusCircleIcon class="icon" />
          </router-link>
          <router-link to="/reference/import" class="btn accent" title="Create New">
            <CloudArrowUpIcon class="icon" />
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-5 mt-4 gap-2">
        <button @click.prevent="() => {
          setFilter('all');
        }
          " class="btn-plain bg-sky-500">
          All
        </button>
        <button class="btn-plain bg-indigo-500" @click.prevent="() => {
          setFilter('m1e');
        }
          ">
          Events
        </button>
        <button class="btn-plain bg-teal-500" @click.prevent="() => {
          setFilter('m1p');
        }
          ">
          PTS
        </button>
        <button class="btn-plain bg-rose-500" @click.prevent="() => {
          setFilter('m1s');
        }
          ">
          365
        </button>
        <button class="btn-plain bg-pink-500" @click.prevent="() => {
          setFilter('m1t');
        }
          ">
          Trainining
        </button>
      </div>
    </div>
    <table class="box list" v-if="!list.isLoading">
      <tbody>
        <tr v-for="row in list.getIndex" :key="row.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          @click.prevent="view(row)">
          <td class="p-4 text-center w-10 lg:w-32 min-w-10 w-32 hidden sm:table-cell">
            <Reference :reference="row" class="w-24" />
          </td>
          <td class="p-4">
            {{ row.name }}
          </td>
          <td class="p-4 truncate w-16 hidden xl:table-cell">
            <span :title="dayjs(row?.updated_at).format('LL')">{{ dayjs(row?.updated_at).fromNow()
              }}</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="8" class="p-4" v-if="list.getIndex.length < 1">
            No data to display
          </td>
        </tr>
        <tr>
          <th colspan="8" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>
    <table class="box list" v-else>
      <tbody>
        <!-- Repeat this skeleton row as needed -->
        <tr v-for="n in 24" :key="n" class="border-b border-gray-300 animate-pulse">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 text-right hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="text-right w-4">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import apiClient from '@/apiClient.js'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { useRouter } from 'vue-router'

const router = useRouter()

const emit = defineEmits(['update:result', 'delete'])

const props = defineProps({
  title: { default: 'Delete' },
  url: { type: String, required: false, default: null },
  redirect: { required: false },
  disableTitle: { type: Boolean, required: false, default: false },
})

const run = () => {
  if (!confirm('Are you sure you want to delete?')) return
  emit('delete', true)
  if (props.url === null) return

  // Send delete request then optionally redirect user to another page
  apiClient.delete(props.url).then(response => {
    emit('update:result', response.data.deleted_at)

    if (props.redirect) {
      router.push(props.redirect)
    }
  })
}
</script>
<template>
  <button class="btn del" @click.prevent="run">
    <TrashIcon class="icon" />
    <template v-if="!disableTitle">{{ props.title }}</template>
  </button>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import apiClient from '@/apiClient.js'
import { useBreadcrumbStore } from '@/stores/BreadcrumbStore'
import TrainingCourses from '@/components/TrainingCourses'
import EditButton from '@/components/EditButton.vue'
import { PaperClipIcon } from '@heroicons/vue/24/solid'
import SearchContact from '@/components/SearchContact'
import TableList from '@/components/TableList.vue'

const route = useRoute()
const router = useRouter()
const breadcrumbStore = useBreadcrumbStore()
const dayjs = inject('dayjs')

let editable = ref(false)
let staff = ref({})
let skills = ref({})
let selectedSkills = ref([])
let photo = ref([])
let photoUrl = ref('')
let photoUploading = ref(false)
let badge = ref('')
let badgeDecoded = ref('')

const onFileChange = event => {
  photo.value = event.target.files[0]
  uploadPhoto()
}

const uploadPhoto = async () => {
  if (!photo.value) {
    return
  }
  photoUploading.value = true

  const formData = new FormData()
  formData.append('photo', photo.value)

  try {
    const response = await apiClient
      .post('/api/staff/' + staff.value.id + '/upload-photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        photoUploading.value = false
        getPhoto()
        getBadge()
      })
  } catch (error) {
    console.error('Error uploading file', error)
  }
}

const getPhoto = () => {
  apiClient.get('/api/staff/' + staff.value.id + '/photo').then(r => {
    photoUrl.value = r.data.image
  })
}

const getBadge = () => {
  apiClient.get('/api/staff/' + staff.value.id + '/badge').then(r => {
    badge.value = r.data
  })
}

const getSkills = () => {
  apiClient.get('/api/staff/skills').then(r => {
    skills.value = r.data
  })
}

const generateIdBadge = () => {
  if (!confirm('Are you sure you want to void the existing ID badge?')) {
    return
  }
  apiClient
    .post('/api/staff/' + staff.value.id + '/badge')
    .then(r => {
      badge.value = r.data
    })
    .then(() => getBadge())
}

const addEmergencyContact = async contact => {
  try {
    // Await the response from the API call
    const response = await apiClient.post(
      `/api/contact/${staff.value.contact.id}/emergency-contact`,
      {
        emergency_contact_id: contact.id,
      },
    )

    // Assuming a 200 OK response means success
    if (response && response.status === 200) {
      staff.value.contact.emergency_contacts.push(contact)
    }
  } catch (error) {
    console.error('Error adding emergency contact')
  }
}

const deleteEmergencyContact = async contact => {
  try {
    // Send the DELETE request
    await apiClient.delete(
      `/api/contact/${staff.value.contact.id}/emergency-contact/${contact.id}`,
    )

    // If successful, remove the contact from the emergency_contacts array
    const index = staff.value.contact.emergency_contacts.findIndex(
      c => c.id === contact.id,
    )
    if (index !== -1) {
      staff.value.contact.emergency_contacts.splice(index, 1)
    }
  } catch (error) {
    console.error('Failed to delete emergency contact:', error)
  }
}

onMounted(() => {
  apiClient
    .get('/api/staff/' + route.params.id)
    .then(r => {
      staff.value = r.data
      staff.value.active = !!staff.value.active

      // Primary contact address
      staff.value.contact.addr = staff.value.contact?.address[0]?.data

      // Skills
      staff.value.skills.forEach(skill => {
        selectedSkills.value.push({ id: skill.id, name: skill.name })
      })

      breadcrumbStore.setValue(staff.value.contact.name)
    })
    .then(() => {
      getPhoto()
      getSkills()
      getBadge()
    })
})

const deleteStaff = () => {
  apiClient.delete(`/api/staff/${staff.value.id}`)
  router.push('/staff')
}

const edit = () => {
  editable.value = true
}
const save = () => {
  editable.value = false

  // Update staff record
  apiClient
    .post('/api/staff/' + staff.value.id, {
      _method: 'PUT',
      active: staff.value.active,
      joined_at: staff.value.joined_at,
      induction_at: staff.value.induction_at,
      contact: { ...staff.value.contact },
      dbsNumber: staff.value.dbsNumber,
      dbsDate: staff.value.dbsDate,
      dbsUpdateService: staff.value.dbsUpdateService,
      drivingLicenseCheck: staff.value.drivingLicenseCheck,
      drivingAssessment: staff.value.drivingAssessment,
      notes: staff.value.notes,
      national_insurance: staff.value.national_insurance,
      registration_number: staff.value.registration_number,
      skills: selectedSkills.value.map(skill => skill.id),
      uniform_shirt: staff.value.uniform_shirt,
      uniform_jacket: staff.value.uniform_jacket,
      uniform_trousers: staff.value.uniform_trousers,
      primary_skill:
        selectedSkills.value.length === 0 ? null : staff.value.primary_skill,
    })
    .then(() => {
      getBadge()
    })
    .catch(e => {})
}

const isSkillSelected = skill => {
  return selectedSkills.value.some(s => s.id === skill.id)
}

const toggleSkillSelection = skill => {
  const index = selectedSkills.value.findIndex(s => s.id === skill.id)
  if (index > -1) {
    selectedSkills.value.splice(index, 1) // Remove if it exists
  } else {
    selectedSkills.value.push({ id: skill.id, name: skill.name }) // Add if it doesn't
  }
}
</script>
<template>
  <div v-if="staff.id">
    <form-box :header="staff.contact?.name + ' - #' + staff.id">
      <template v-slot:nav>
        <DeleteButton @delete="deleteStaff" />
        <EditButton @click.prevent="edit" v-if="!editable" />
        <SaveButton
          @click.prevent="save"
          title="Save"
          v-if="editable"
          :hideCancel="false"
        />
      </template>
    </form-box>

    <div class="box flex justify-center">
      <div class="flex flex-col md:flex-row gap-8 items-center mx-auto">
        <div
          class="border w-48 h-48 rounded-xl flex justify-center items-center overflow-hidden bg-gray-300"
        >
          <img
            :src="photoUrl"
            v-if="photoUrl && photoUrl.length > 50"
            class="w-full h-full object-cover"
          />
          <div class="animate-pulse" v-if="photoUploading">
            <div class="h-4 bg-gray-300 rounded w-full h-full"></div>
          </div>
        </div>
        <div class="text-center">
          <input
            type="file"
            ref="fileInput"
            class="hidden"
            @change="onFileChange"
            accept="image/png, image/jpeg"
          />
          <br />
          <EditButton @edit="$refs.fileInput.click()" title="Photo" /><br />
          <span class="text-xs text-center">Accepts jpg/png</span>
        </div>
      </div>
    </div>

    <form-box header="Status">
      <form
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl"
      >
        <div>
          <label for="joined">Joined Date</label>
          <DateField
            :data="staff.joined_at"
            :editable="editable"
            @update="val => (staff.joined_at = val)"
          />
        </div>

        <div>
          <label for="induction">Induction Date</label>
          <DateField
            :data="staff.induction_at ?? null"
            :editable="editable"
            @update="val => (staff.induction_at = val)"
          />
        </div>

        <div>
          <label for="active">Active</label>
          <input type="checkbox" v-model="staff.active" v-if="editable" />
          <span v-else>{{ staff.active ? 'Yes' : 'No' }}</span>
        </div>
      </form>
    </form-box>

    <form-box header="Personal Information">
      <form
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-full"
      >
        <div>
          <label for="name">Name</label>
          <TextField
            :data="staff.contact.name"
            :editable="editable"
            @update="val => (staff.contact.name = val)"
          />
        </div>

        <div>
          <label for="name">Nickname</label>
          <TextField
            :data="staff.contact.nickname"
            :editable="editable"
            @update="val => (staff.contact.nickname = val)"
          />
        </div>

        <div>
          <label for="email">Email Address</label>
          <TextField
            :data="staff.contact.email ?? null"
            :editable="editable"
            @update="val => (staff.contact.email = val)"
          />
        </div>

        <div>
          <label for="phone">Phone Number</label>
          <TextField
            :data="staff.contact.phone ?? null"
            :editable="editable"
            @update="val => (staff.contact.phone = val)"
          />
        </div>
        <div class="">
          <label for="sex">Sex</label>
          <select
            id="sex"
            v-model="staff.contact.sex"
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            v-if="editable"
          >
            <option value="0">Male</option>
            <option value="1">Female</option>
          </select>
          <template v-else>
            <span v-if="staff.contact.sex == 0">Male</span>
            <span v-else>Female</span>
          </template>
        </div>
        <div>
          <label title="Date of Birth">DOB</label>
          <DateField
            :data="staff.contact.dob ?? null"
            :editable="editable"
            @update="val => (staff.contact.dob = val)"
          />
        </div>
        <div>
          <label>National Insurance</label>
          <TextField
            :data="staff.national_insurance ?? null"
            :editable="editable"
            @update="val => (staff.national_insurance = val)"
          />
        </div>
        <div>
          <label>Registration Number</label>
          <TextField
            :data="staff.registration_number ?? null"
            :editable="editable"
            @update="val => (staff.registration_number = val)"
          />
        </div>
        <div>
          <label for="contact_address">Address</label>
          <textarea
            class="w-full"
            id="contact_address"
            v-model="staff.contact.addr"
            :disabled="!editable"
          ></textarea>
        </div>
      </form>
    </form-box>

    <form-box header="Uniform">
      <form class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl">
        <div>
          <label>Shirt</label>
          <select v-model="staff.uniform_shirt" v-if="editable">
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="XXXL">XXXL</option>
          </select>
          <span v-else>{{ staff.uniform_shirt }}</span>
        </div>
        <div>
          <label>Jacket</label>
          <select v-model="staff.uniform_jacket" v-if="editable">
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="XXXL">XXXL</option>
          </select>
          <span v-else>{{ staff.uniform_jacket }}</span>
        </div>
        <div>
          <label>Trousers</label>
          <select v-model="staff.uniform_trousers" v-if="editable">
            <option value="26 inch">26 inch</option>
            <option value="28 inch">28 inch</option>
            <option value="30 inch">30 inch</option>
            <option value="31 inch">31 inch</option>
            <option value="32 inch">32 inch</option>
            <option value="33 inch">33 inch</option>
            <option value="34 inch">34 inch</option>
            <option value="36 inch">36 inch</option>
            <option value="38 inch">38 inch</option>
            <option value="40 inch">40 inch</option>
            <option value="42 inch">42 inch</option>
            <option value="44 inch">44 inch</option>
            <option value="46 inch">46 inch</option>
            <option value="48 inch">48 inch</option>
            <option value="Womens 8">Womens 8</option>
            <option value="Womens 10">Womens 10</option>
            <option value="Womens 12">Womens 12</option>
            <option value="Womens 14">Womens 14</option>
            <option value="Womens 16">Womens 16</option>
            <option value="Womens 18">Womens 18</option>
          </select>
          <span v-else>{{ staff.uniform_trousers }}</span>
        </div>
      </form>
    </form-box>

    <form-box header="Emergency Contacts">
      <SearchContact @selected="addEmergencyContact" />
      <TableList
        :modelValue="staff.contact.emergency_contacts"
        :cols="[
          { name: 'Name', row: 'name', editable: true },
          { name: 'Email', row: 'email', editable: true },
          { name: 'Phone', row: 'phone', editable: true },
        ]"
        :deletable="true"
        :box="false"
        @delete="deleteEmergencyContact"
      />
    </form-box>

    <form-box header="Safety Checks">
      <form
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl"
      >
        <div>
          <label for="dbsNumber">DBS Number</label>
          <TextField
            :data="staff.dbsNumber ?? null"
            :editable="editable"
            @update="val => (staff.dbsNumber = val)"
          />
        </div>

        <div class="">
          <label for="dbsDate">DBS Date</label>
          <DateField
            :data="staff.dbsDate ?? null"
            :editable="editable"
            @update="val => (staff.dbsDate = val)"
          />
        </div>
        <div class="">
          <label for="dbsUpdateService">Update Service</label>
          <select
            id="dbsUpdateService"
            v-model="staff.dbsUpdateService"
            class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            v-if="editable"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <template v-else>
            <span v-if="staff.dbsUpdateService == 0">No</span>
            <span v-else>Yes</span>
          </template>
        </div>

        <div class="">
          <label for="drivingLicenseCheck">Driving License Check</label>
          <DateField
            :data="staff.drivingLicenseCheck ?? null"
            :editable="editable"
            @update="val => (staff.drivingLicenseCheck = val)"
          />
        </div>
        <div class="">
          <label for="drivingAssessment">Driving Assessment</label>
          <DateField
            :data="staff.drivingAssessment ?? null"
            :editable="editable"
            @update="val => (staff.drivingAssessment = val)"
          />
        </div>
      </form>
    </form-box>

    <form-box header="Skills">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
      >
        <div v-for="skill in skills" :key="skill.id" class="flex items-center">
          <input
            type="checkbox"
            :id="'checkbox-' + skill.id"
            class="form-checkbox"
            :disabled="!editable"
            :checked="isSkillSelected(skill)"
            @change="toggleSkillSelection(skill)"
          />
          <label :for="'checkbox-' + skill.id" class="ml-2">{{
            skill.name
          }}</label>
        </div>
      </div>
      <div>
        <label class="mt-4">Primary Skill</label>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <select v-model="staff.primary_skill" :disabled="!editable">
            <option
              v-for="skill in selectedSkills"
              :key="skill.id"
              :value="skill.id"
            >
              {{ skill.name }}
            </option>
          </select>
          <span>Primary skill shows on ID Badge</span>
        </div>
      </div>
    </form-box>

    <form-box header="Training">
      <TrainingCourses :staff="staff" v-if="staff.courses" />
    </form-box>

    <form-box header="ID Badge">
      <div
        class="flex flex-col md:flex-row gap-8 items-center mx-auto border-0"
      >
        <img
          :src="badge.image"
          class="w-full max-h-64 object-contain"
          v-if="badge.image"
        />
        <div
          class="h-64 w-full p-8 bg-gray-300 flex ring-2 ring-gray-300 dark:ring-gray-500 rounded text-center justify-center items-center"
          v-else
        >
          Badge does not exist and must be generated
        </div>
        <div class="w-full">
          <div v-if="badge && badge.meta">
            <label>Created</label>
            <span>{{ new Date(badge.meta.created_at).toLocaleString() }}</span>
            <label class="mt-4">Expiry</label>
            <span>{{ new Date(badge.meta.expiration).toLocaleString() }}</span>
          </div>
          <br />
          <br />
          <div>
            <button class="btn mt-4" @click.prevent="generateIdBadge">
              Generate
            </button>
            <DownloadButton
              :url="'/api/staff/' + staff.id + '/badge?download=1'"
              :filename="staff.contact.name + ' ID badge.png'"
            />
          </div>
          <br />
          <div class="py-4 text-xs">
            Generating a new ID Badge will void any exising ID badge. A voided
            ID badge will shown "Status: Void" when the QR Code is scanned.
          </div>
        </div>
      </div>
    </form-box>

    <form-box header="Notes">
      <textarea v-model="staff.notes" class="w-full min-h-24" v-if="editable" />
      <span v-else>{{ staff.notes ?? 'No notes available' }}</span>
    </form-box>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { FlagIcon } from '@heroicons/vue/24/solid'
import { v4 as uuidv4 } from 'uuid'

const emits = defineEmits([
  'tableResults',
  'view',
  'sort',
  'save',
  'delete',
  'updatePrice',
  'update',
])

const props = defineProps({
  cols: Array,
  attributes: { type: Object, default: null },
  modelValue: Object,
  class: String,
  store: Object,
  box: { type: Boolean, default: true },
  addable: { type: Boolean, default: false },
  editable: { type: Boolean, default: false },
  deletable: { type: Boolean, default: false },
  initialRows: { type: Number, default: -1 },
})

// Number of rows to show
const rowLimit = ref(props.initialRows)
const computedRows = computed(() => {
  // If rowLimit is -1, show all rows, otherwise show up to rowLimit rows
  return rowLimit.value === -1
    ? props.modelValue
    : props.modelValue.slice(0, rowLimit.value)
})
const remainingRows = computed(() =>
  parseInt(props.modelValue.length - rowLimit.value),
)
const increaseRowLimit = () => (rowLimit.value += 2 * props.initialRows)
const removeRowLimit = () => (rowLimit.value = -1)

const getValue = (col, val) => (col.run ? col.run(val) : val[col.row])

const setValue = (line, col, row, value) => {
  line[col.row] = value
}

let editingId = ref(-1) // Current id being edited
const setEditable = id => (editingId.value = id)
const setUneditable = id => (editingId.value = -1)
const isEditable = (col, line) => {
  return (
    (col.editable && line.id === editingId.value) ||
    (col.editable && line.uuid === editingId.value)
  )
}
const editableType = (col, id) => {
  return col.type ?? 'input'
}
const save = line => {
  emits('save', line)
  editingId.value = -1
}

/*
 Add a blank entry line but do not persist any data until saveEntry is called
 */
const addEntry = () => {
  let newEntry = {}
  props.cols.forEach(function (col) {
    if (col.isEditable === false) return
    newEntry[col.row] = ''
  })

  newEntry.uuid = uuidv4()
  setEditable(newEntry.uuid)
  emits('update', [...props.modelValue, newEntry])
}

/* 
    Delete entry with confirmation
    Emits 'delete' with entry as argument
*/
const deleteEntry = line => {
  emits('delete', line)
}
</script>
<template>
  <div :class="{ box: props.box }" v-if="props.modelValue">
    <table class="md:table-auto w-full text-left mb-2">
      <thead>
        <tr class="text-left">
          <th v-for="col in props.cols" :key="col" :class="[col.class, { 'hidden md:inline': col.optional }]">
            <span v-if="col.sortBy">
              {{ col.name }}
              <button @click.prevent="emits('sort', col.sortBy)" class="text-xs">
                &#8693;
              </button>
            </span>
            <span v-else>{{ col.name }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="line in computedRows" :key="line.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer" :class="[
            {
              'font-bold font-dark:text-white':
                attributes?.highlightRow?.(line) == 1,
              'text-gray-700 dark:text-gray-400':
                attributes?.highlightRow?.(line) != 1,
            },
          ]" @click.prevent="emits('view', line)">
          <td v-for="col in props.cols" :key="col" class="pr-3 py-3" :class="[
            col.class,
            col.type === 'price' ? 'currency' : '',
            { 'hidden md:inline': col.optional },
          ]">
            <!-- Editable content -->
            <textarea v-if="
              isEditable(col, line) &&
              editableType(col, line.id) == 'textarea'
            " type="text" class="w-full m-h-full h-24" v-model="line[col.row]"></textarea>
            <input v-else-if="
              isEditable(col, line) && editableType(col, line.id) == 'date'
            " type="date" v-model="line[col.row]" />
            <input v-else-if="
              isEditable(col, line) && editableType(col, line.id) == 'bool'
            " type="checkbox" v-model="line[col.row]" />
            <CurrencyInput v-else-if="
              isEditable(col, line) &&
              editableType(col, line.id) == 'currency'
            " type="text" :price="line[col.row]" @update:price="price => setValue(line, col, row, price)" />
            <input v-else-if="isEditable(col, line)" type="text" v-model="line[col.row]" />

            <!-- Basic text content -->
            <template v-else>
              <span v-if="col?.type === 'currency'" class="whitespace-nowrap">
                &pound;
                <CurrencyOutput :price="getValue(col, line)" />
              </span>

              <span v-else-if="col?.type === 'flag'">
                <FlagIcon class="icon" v-if="getValue(col, line) === true" />
              </span>

              <span v-else class="truncate">{{ getValue(col, line) }}</span>
            </template>
          </td>
          <td v-if="props.editable || props.deletable" class="text-right">
            <edit-button v-if="
              editingId !== line.id &&
              editingId !== line.uuid &&
              props.editable
            " :disableTitle="true" @click.prevent="setEditable(line.id)" />
            <save-button :disableTitle="true" v-if="
              (editingId == line.id || editingId == line.uuid) &&
              props.editable
            " @click.prevent="save(line)" />
            <delete-button v-if="props.deletable" @click.prevent="deleteEntry(line)" title="" />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="addable">
      <button class="btn add" @click.prevent="addEntry">+ Add</button>
    </div>

    <div v-if="remainingRows > 0 && rowLimit != -1" class="text-center flex gap-2 justify-center">
      <button class="btn my-2" @click.prevent="increaseRowLimit">
        Show More
      </button>
      <button class="btn my-2" @click.prevent="removeRowLimit">Show All</button>
    </div>
  </div>
  <LoadingSpinner v-else />
</template>
<style>
tr:nth-child(even) td {
  filter: brightness(95%);
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import apiClient from '@/apiClient.js'
import { useRoute, useRouter } from 'vue-router'
import SearchCustomer from '@/components/SearchCustomer'

const route = useRoute()
const router = useRouter()
const reference = ref({})
const referenceTypes = ref([])
const customer = ref({})

onMounted(() => {
  apiClient.get('/api/reference/types').then(r => {
    referenceTypes.value = r.data
    reference.value.type_id = referenceTypes.value[0].id
  })
})

const selectCustomer = val => {
  reference.value.customer_id = val.id
  customer.value = val
}

const removeCustomer = () => {
  reference.value.customer_id = null
  customer.value = null
}

const btnSave = val => {
  if (
    !reference.value.customer_id ||
    !reference.value.type_id ||
    !reference.value.name
  ) {
    return alert('All fields must be complete before creating a job')
  }
  apiClient.post('/api/reference', reference.value).then(r => {
    router.push({
      name: route.query.returnPath ?? 'reference-view',
      params: { id: r.data.id },
    })
  })
}
</script>
<template>
  <div>
    <form-box header="Create Job" class="[&>*]:block [&>*]:w-full [&>*]:mb-4">
      <label>Customer</label>
      <div v-if="customer?.company">
        <span>{{ customer.company }} </span>
        <button @click.prevent="removeCustomer" class="ml-2 text-blue-800">
          [x]
        </button>
      </div>
      <SearchCustomer @selected="selectCustomer" v-else />
      <label>Type</label>
      <select v-model="reference.type_id">
        <option v-for="type in referenceTypes" :key="type.id" :value="type.id">
          {{ type.name }}
        </option>
      </select>
      <label>Job Name</label>
      <textarea v-model="reference.name"></textarea>
      <SaveButton @save="btnSave" />
    </form-box>
  </div>
</template>

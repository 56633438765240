<script setup>
import apiClient from '@/apiClient.js'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SearchCustomer from '@/components/SearchCustomer.vue'
import { PencilIcon } from '@heroicons/vue/24/solid'
import SaveButton from '@/components/SaveButton.vue'

const referenceTypes = ref({})
const selectedCustomer = ref({})
const invoiceType = ref('')
const invoiceDescription = ref('')
const router = useRouter()
const route = useRoute()

onMounted(() => {
  let customer_id = route.query.customerId

  apiClient
    .get('/api/reference/types')
    .then(result => (referenceTypes.value = result.data))
})

const customerSelected = customer => (selectedCustomer.value = customer)

const resetCustomer = () => (selectedCustomer.value = {})

const createInvoice = async () => {
  let invoice = {
    customer_id: selectedCustomer.value.id,
    type_id: invoiceType.value,
    name: invoiceDescription.value,
  }

  const referenceResponse = await apiClient.post('/api/reference', invoice)
  const invoiceResponse = await apiClient.post('/api/invoice', {
    reference_id: referenceResponse.data.id,
  })

  router.push('/invoice/' + invoiceResponse.data.id)
}
</script>
<template>
  <form-box header="New Invoice">
    <form class="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-xl2">
      <div>
        <label>Customer</label>
        <SearchCustomer
          @selected="customerSelected"
          returnPath="/invoice/create"
          v-show="!selectedCustomer.id"
        />
        <div
          class="flex items-center justify-between"
          v-show="selectedCustomer.id"
        >
          <input
            type="text"
            :value="selectedCustomer.company"
            :disabled="true"
          />
          <PencilIcon class="icon text-right" @click.prevent="resetCustomer" />
        </div>
      </div>

      <div>
        <label>Type</label>
        <select v-model="invoiceType">
          <option>- Select -</option>
          <option
            v-for="type in referenceTypes"
            :value="type?.id"
            :key="type?.id"
          >
            {{ type?.name }}
          </option>
        </select>
      </div>

      <div>
        <label>Description</label>
        <input
          type="text"
          v-model="invoiceDescription"
          :class="{ 'border-red-500': invoiceDescription?.length < 1 }"
          class="w-full"
        />
      </div>
    </form>
    <br />
    <SaveButton
      hideCancel="true"
      title="Create"
      @click.prevent="createInvoice"
    />
  </form-box>
</template>

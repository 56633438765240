<script setup>
import { ref } from 'vue'
import { ArrowDownCircleIcon } from '@heroicons/vue/24/solid'
import apiClient from '@/apiClient.js'

const props = defineProps({
  title: { default: 'Download' },
  disableTitle: { type: Boolean, required: false, default: false },
  filename: { type: String, required: true },
  url: { type: String, required: false },
})

const downloadButton = () => {
  const response = apiClient
    .get(props.url, {
      responseType: 'blob',
    })
    .then(response => {
      console.log(response.data)
      console.log(props.filename)
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = props.filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
}
</script>
<template>
  <div class="inline">
    <button class="btn action" @click.prevent="downloadButton">
      <ArrowDownCircleIcon class="icon" />
      <template v-if="!disableTitle">{{ props.title }}</template>
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import apiClient from '@/apiClient.js'
import { useCalendarStore } from '@/stores/CalendarStore'
import { useRouter } from 'vue-router'
import ReferenceSearch from '@/components/ReferenceSearch'

const router = useRouter()
const referenceTypes = ref([])
const entry = ref({})
const calendarStore = useCalendarStore()
const multipleRanges = ref()

onMounted(async () => {
  const response = await apiClient.get('/api/reference/types')
  referenceTypes.value = response.data
})

const save = async () => {
  if (
    !entry.value.reference_type_id ||
    !entry.value.date ||
    entry.value.description.length < 1
  ) {
    return alert('Complete all fields before continuing')
  }

  await calendarStore.createEntry(entry.value)
  router.push('/calendar?date=' + entry.value.date)
}
</script>
<template>
  <div>
    <ReferenceSearch />
    <form-box header="Calendar Entry">
      <form class="w-full max-w-lg">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label>Type</label>
            <select v-model="entry.reference_type_id">
              <option
                v-for="type in referenceTypes"
                :key="type"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label>Date</label>
            <DatePicker
              v-model="multipleRanges"
              :range="true"
              :multiple="true"
              class="pr-6"
            />
          </div>
        </div>

        <div>
          <label>Description</label>
          <input
            type="text"
            v-model="entry.description"
            class="w-full"
            placeholder="Calivon Zoo Festival"
          />
        </div>

        <div class="mt-6">
          <SaveButton title="Save" @click.prevent="save" />
        </div>
      </form>
    </form-box>
  </div>
</template>

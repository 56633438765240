<script setup>
import { ref } from 'vue'
import useDebouncedFunction from '@/composables/useDebouncedFuncion'
import apiClient from '@/apiClient.js'
const props = defineProps([
  'output',
  'url',
  'placeholder',
  'modelValue',
  'canAdd',
])
const emit = defineEmits(['selected', 'update:modelValue', 'empty', 'found'])
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const searchQueryLocal = ref('')
const searchResults = ref()
const showResults = ref(true)
const searchResultElements = ref([])
const loadingResults = ref(false)
const selectedItemIndex = ref(-1)

const addBtn = () => {
  showResults.value = false
  emit('empty')
}

const { debouncedFunction: search, disableTimer } = useDebouncedFunction(() => {
  if (searchQueryLocal.value.length < 1) {
    searchResults.value = null
    return
  }
  loadingResults.value = true
  apiClient
    .get(props.url + encodeURIComponent(searchQueryLocal.value))
    .then(r => {
      loadingResults.value = false

      // no results found
      if (
        r.data &&
        typeof r.data.length !== 'undefined' &&
        r.data.length === 0
      ) {
        showResults.value = false
        emit('update:modelValue', searchQueryLocal.value)
        emit('empty')
      }
      // display results
      else {
        searchResults.value = r.data
        showResults.value = true
        selectedItemIndex.value = -1
        emit('found', searchResults.value)
      }
    })
})

function handleKeyUp(e) {
  // Search when user presses a key that's not an arrow key
  if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
    return search()
  }
  if (e.keyCode === 40) {
    return
  } else if (e.keyCode === 38) {
    return
  } else if (e.keyCode === 13) {
    // Enter key
    if (selectedItemIndex.value > 0)
      selected(searchResults.value[selectedItemIndex.value])
  }
}

function selected(val) {
  disableTimer() // Cancel the debounced function to disable results popping up again
  showResults.value = false
  emit('selected', val)
  searchQueryLocal.value = ''
}
</script>
<template>
  <div class="relative">
    <input
      type="text"
      v-model="searchQueryLocal"
      @keyup="handleKeyUp"
      class="w-full"
      autocomplete="off"
      :placeholder="props.placeholder"
    />
    <div
      v-if="showResults && searchQueryLocal"
      class="absolute z-50 left-0 right-0 mt-2 bg-white text-black border border-gray-300 rounded-md shadow-lg max-h-36 overflow-y-auto"
    >
      <button
        v-if="props.canAdd"
        class="p-2 hover:bg-slate-300 hover:cursor-pointer block w-full text-left"
        @click.prevent="addBtn"
      >
        + Add <template>{{ searchQueryLocal }}</template>
      </button>
      <div
        v-for="(item, i) in searchResults"
        :key="item"
        @click="selected(item)"
        :class="{
          'p-2 hover:bg-slate-300 hover:cursor-pointer': true,
          'bg-slate-200': selectedItemIndex === i,
        }"
      >
        <template v-if="props.output(item)">{{ props.output(item) }}</template>
      </div>
    </div>
    <div
      v-if="loadingResults"
      class="absolute z-50 left-0 right-0 mt-2 bg-white text-black border border-gray-300 rounded-md shadow-lg max-h-36 overflow-y-auto"
    >
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>

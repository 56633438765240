// usePagination.js
import { useRouter, useRoute } from 'vue-router';

export function usePagination(list, filter) {
  const router = useRouter();
  const route = useRoute();

  const setUrl = () => {
    const page = list.getPage;
    const af = filter.value;
    router.push({
      path: route.path,
      query: { ...route.query, page, af },
    });
  };

  const changePage = (val) => {
    list.page(val);
    setUrl();
  };

  return {
    setUrl,
    changePage,
  };
}


<script setup>
import { ref } from 'vue'
import apiClient from '@/apiClient.js'
import { useRoute, useRouter } from 'vue-router'
import SearchContact from '@/components/SearchContact'

const route = useRoute()
const router = useRouter()

async function saveStaff(contact) {
  apiClient
    .post('/api/staff', { contact_id: contact.id })
    .then(r => {
      router.push({
        name: route.query.returnPath ?? 'staff-view',
        params: { id: r.data.id },
      })
    })
    .catch(e => {})
}
</script>
<template>
  <div>
    <form-box header="Staff Information">
      <div>Enter name or email of staff member</div>
      <SearchContact class="mt-4" @selected="saveStaff" />
    </form-box>
  </div>
</template>

import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@/apiClient.js'

export let useAuthStore = defineStore('auth', {
  state() {
    return {
      authenticated: ref(false),
      loginError: false,
    }
  },

  actions: {
    setLoggedIn() {
      this.authenticated = true
    },

    async tryLogin(email, password) {
      if (!email.length || !password.length) {
        return false
      }

      try {
        const response = await apiClient.post('/login', { email, password })
        console.log(response)
        this.renewTimeoutCookie()
        this.authenticated = true
        localStorage.setItem('user', JSON.stringify(response.data.user))
        return true
      } catch (e) {
        return false
      }
    },

    renewTimeoutCookie() {
      let now = new Date()
      let expiry = now.getTime() + 2 * 60 * 60 * 1000 // 2 hour expiry
      localStorage.setItem('authenticated', expiry)
    },

    logout() {
      localStorage.removeItem('authenticated')
      localStorage.removeItem('user')
      this.authenticated = false
    },

    clearErrors() {
      this.loginError = false
    },
  },

  getters: {
    isLoggedIn() {
      let now = new Date()
      let expiry = localStorage.getItem('authenticated')
      if (parseInt(expiry) > now.getTime()) this.authenticated = true

      return this.authenticated
    },

    hasError() {
      return this.loginError
    },

    user() {
      return JSON.parse(localStorage.getItem('user'))
    },

    name() {
      return JSON.parse(localStorage.getItem('user')).name
    },

    email() {
      return JSON.parse(localStorage.getItem('user')).email
    },
  },
})

<script setup>
import { defineProps, onMounted, ref, } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { FlagIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/solid";
import TicketService from "@/services/TicketService"
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import dayjs from "dayjs";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const list = useListStoreNew();
const counters = ref({});
const route = useRoute();
const router = useRouter();
const filter = ref("");
const ticketService = new TicketService()

const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

const props = defineProps({
  showSearch: { type: Boolean, required: false, default: true },
  showLabel: { type: Boolean, required: false, default: true },
  showName: { type: Boolean, required: false, default: true },
  showDate: { type: Boolean, required: false, default: true },
})

onMounted(async () => {
  list.configure({ remote: "/api/ticket", local: "/tickets", });
  counters.value = await ticketService.counters();
  filter.value = route.query?.af ?? "unanswered" // default filter to apply to list
  applyFilter()
  list.fetchIndex()
});

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.page === undefined) {
    load();
  }
  next();
});

function lastExternalSender(items) {
  if (!items || items.length === 0) return "n/a";
  const lastItem = items[items.length - 1];
  return lastItem.contact?.name || lastItem.user?.name || "n/a";
}

const applyFilter = () => {
  list.clearFilters();
  if (filter.value === "unanswered") {
    list.addFilter("awaiting_response", "1");
  } else if (filter.value === "flagged") {
    list.addFilter("flagged", "1");
  }
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const load = () => {
  applyFilter();
  setUrl();
  list.resetPage();
};


const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }
  router.push({
    name: "tickets-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.subject);
};
</script>
<template>
  <div>
    <div class="box" v-show="props.showSearch">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/ticket/search?query=" :output="(val) => {
          return val.subject + ' [' + lastExternalSender(val.items) + ']';
        }
          " @selected="view" />
        <router-link to="/tickets/create" class="btn accent" title="Create New">
          <PlusCircleIcon class="icon" />
        </router-link>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 mt-4 gap-2">
        <button @click.prevent="() => {
          setFilter('all');
        }
          " class="btn-plain bg-sky-500">
          All
          <span class="text-xs">({{ counters.total }})</span>
        </button>
        <button class="btn-plain bg-indigo-500" @click.prevent="() => {
          setFilter('unanswered');
        }
          ">
          Unanswered
          <span class="text-xs">({{ counters.unanswered }})</span>
        </button>
        <button class="btn-plain bg-teal-500" @click.prevent="() => {
          setFilter('flagged');
        }
          ">
          Flagged
          <span class="text-xs">({{ counters.flagged }})</span>
        </button>
      </div>
    </div>
    <table class="box list" v-if="!list.isLoading">
      <tbody>
        <tr v-for="row in list.getIndex" :key="row.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          :class="{ 'font-bold ': row.awaitingResponse }" @click.prevent="view(row)">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10" v-show="props.showLabel">
            <label class="label-event" v-if="row.channel == 'events'">
              <span class="hidden lg:inline">Event</span>
              <span class="lg:hidden">E</span>
            </label>
            <label class="label-transport" v-else-if="row.channel == 'transport'">
              <span class="hidden lg:inline">Transport</span>
              <span class="lg:hidden">T</span>
            </label>
            <label class="label-job" v-else-if="row.channel == 'vacancies'">
              <span class="hidden lg:inline">Job</span>
              <span class="lg:hidden">j</span>
            </label>
            <label class="label-junk" v-else-if="row.channel == 'junk'">
              <span class="hidden lg:inline">Junk</span>
              <span class="lg:hidden">
                <TrashIcon />
              </span>
            </label>
            <label class="label-finance" v-else-if="row.channel == 'finance'">
              <span class="hidden lg:inline">Finance</span>
              <span class="lg:hidden">£</span>
            </label>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell" v-show="props.showName">
            <span :title="lastExternalSender(row.items)">{{
              lastExternalSender(row.items)
              }}</span>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            {{ row.subject }}
          </td>
          <td class="p-3 text-right hidden sm:table-cell" v-show="props.showDate">
            {{ dayjs(row.updated_at).fromNow() }}
          </td>
          <td class="text-right w-4">
            <div v-if="row.flagged">
              <FlagIcon class="icon" v-if="row.flagged" />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="p-4" v-if="list.getIndex.length < 1">
            No data to display
          </td>
        </tr>
        <tr>
          <th colspan="5" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>
    <table class="box list" v-else>
      <tbody>
        <!-- Repeat this skeleton row as needed -->
        <tr v-for="n in 24" :key="n" class="border-b border-gray-300 animate-pulse">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="p-3 text-right hidden sm:table-cell">
            <div class="h-6 bg-gray-300 rounded"></div>
          </td>
          <td class="text-right w-4">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
